import React from 'react';

const RenderSessionSummary = ({ treeSession }) => {
  return (
      <div >
        <p>Form Name: {treeSession.treeName} <br />
        Created: {new Date(treeSession.createdAt).toLocaleString()} <br />
        Complete: {treeSession.complete ? 'Yes' : 'No'} </p>
      </div>
  );
}

export default RenderSessionSummary;