import React, { useState } from 'react';
import './serviceModal.css';

const ServiceSelectionModal = ({ services, selectedServices, setSelectedServices, onConfirm, onClose }) => {
  const [quantities, setQuantities] = useState({});

  // Function to handle checkbox changes
  const handleCheckboxChange = (serviceId) => {
    setSelectedServices(prevSelectedServices => {
      if (prevSelectedServices.includes(serviceId)) {
        // If the service is already selected, remove it from the selection
        const newSelected = prevSelectedServices.filter(uuid => uuid !== serviceId);
        // Also remove the quantity
        const newQuantities = {...quantities};
        delete newQuantities[serviceId];
        setQuantities(newQuantities);
        return newSelected;
      } else {
        // Otherwise, add the service to the selection
        return [...prevSelectedServices, serviceId];
      }
    });

    // Initialize quantity to 1 when selecting a service
    if (!quantities[serviceId]) {
      setQuantities(prevQuantities => ({
        ...prevQuantities,
        [serviceId]: 1
      }));
    }
  };

  // Function to handle quantity changes
  const handleQuantityChange = (serviceId, newQuantity) => {
    if (newQuantity >= 1) {
      setQuantities(prevQuantities => ({
        ...prevQuantities,
        [serviceId]: newQuantity
      }));
    }
  };

  // Function to handle confirm button click
  const handleConfirm = () => {
    const selectedServiceObjects = selectedServices.map(uuid => ({
      ...services.find(service => service.uuid === uuid),
      quantity: quantities[uuid] || 1
    }));
    onConfirm(selectedServiceObjects);
  };

  return (
    <div className="modal">
      <h2>Select Services</h2>
      {services.map(service => (
        <div key={service.uuid} className="service-option">
          <label>
            <input
              type="checkbox"
              checked={selectedServices.includes(service.uuid)}
              onChange={() => handleCheckboxChange(service.uuid)}
            />
            {service.name} - ${service.price}
          </label>
          {selectedServices.includes(service.uuid) && (
            <div className="quantity-control">
              <button 
                onClick={() => handleQuantityChange(service.uuid, (quantities[service.uuid] || 1) - 1)}
                disabled={quantities[service.uuid] <= 1}
              >
                -
              </button>
              <input
                type="number"
                min="1"
                value={quantities[service.uuid] || 1}
                onChange={(e) => handleQuantityChange(service.uuid, parseInt(e.target.value) || 1)}
              />
              <button onClick={() => handleQuantityChange(service.uuid, (quantities[service.uuid] || 1) + 1)}>
                +
              </button>
            </div>
          )}
        </div>
      ))}
      <div className="modal-actions">
        <button onClick={handleConfirm}>Add Selected Services</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default ServiceSelectionModal;