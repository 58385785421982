const agreement = `<div>
    <h2 style="text-align: center;"> Patient Waiver</h2>
    <h1>Luxe Mobile IV LLC. - Informed Consent and HIPPA</h1>
    <h2>Intravenous (IV) Infusion Therapy Consent Form</h2>
    <p>This document is intended to serve as informed consent for your Intravenous (IV) Infusion Therapy as ordered by the physician at Luxe Mobile IV.</p>
    <p>I have informed the nurse and/or physician of any known allergies to medications or other substances and of all current medications and supplements. I have fully informed the nurse and/or physician of my medical history.</p>
    <p>Intravenous infusion therapy and any claims made about these infusions have not been evaluated by the US Food and Drug Administration (FDA) and are not intended to diagnose, treat, cure, or prevent any medical disease. These IV infusions are not a substitute for your physician’s medical care.</p>
    <p>I understand that I have the right to be informed of the procedure, any feasible alternative options, and the risks and benefits. Except in emergencies, procedures are not performed until I have had an opportunity to receive such information and to give my informed consent.</p>
    <p>I understand that:</p>
    <ol>
        <li>The procedure involves inserting a needle into a vein and injecting the prescribed solution.</li>
        <li>Alternatives to intravenous therapy are oral supplementation and/or dietary and lifestyle changes.</li>
        <li>Risks of intravenous therapy include but not limited to:
            <ul>
                <li>a) Occasionally: Discomfort, bruising, and pain at the site of injection.</li>
                <li>b) Rarely: Inflammation of the vein used for injection, phlebitis, metabolic disturbances, and injury.</li>
                <li>c) Extremely Rare: Severe allergic reaction, anaphylaxis, infection, cardiac arrest, and death.</li>
            </ul>
        </li>
        <li>Benefits of intravenous therapy include:
            <ul>
                <li>a) Injectable/s are not affected by stomach or intestinal absorption problems.</li>
                <li>b) Total amount of infusion is available to the tissues.</li>
                <li>c) Nutrients are forced into cells by means of a high concentration gradient.</li>
                <li>d) Higher doses of nutrients can be given than possible by mouth without intestinal irritation.</li>
            </ul>
        </li>
    </ol>
    <p>I am aware that other unforeseeable complications could occur. I do not expect the nurse(s) and/or physician(s) to anticipate and explain all risk and possible complications. I rely on the nurse(s) and/or physician(s) to exercise judgment during the course of treatment with regards to my procedure. I understand the risks and benefits of the procedure and have had the opportunity to have all of my questions answered. I understand that I have the right to consent to or refuse any proposed treatment at any time prior to its performance. My signature on this form affirms that I have given my consent to IV Infusion Therapy, including any other procedures which, in the opinion of my physician(s) or other associated with this practice, may be indicated.</p>
    <p>My signature below confirms that:</p>
    <ol>
        <li>I understand the information provided on this form and agree to all statements made above.</li>
        <li>Intravenous (IV) Infusion Therapy has been adequately explained to me by my nurse and/or physician.</li>
        <li>I have received all the information and explanation I desire concerning the procedure.</li>
        <li>I authorize and consent to the performance of Intravenous (IV) Infusion Therapy.</li>
        <li>I release Luxe Mobile IV, and all the medical staff from all liabilities for any complications or damages associated with my Intravenous (IV) Infusion Therapy.</li>
    </ol>
    <h2>HIPAA NOTICE OF PRIVACY PRACTICES</h2>
    <p>At Luxe Mobile IV, we understand that health information about you is very personal and we are mandated by the Health Insurance Portability and Accountability Act (HIPAA) to protect your health information. We create a record of the care and services you receive from us, and this record helps to provide you with quality care and to comply with certain legal requirements. This Notice applies to all of the records of your care generated by us, and informs you about the ways in which we may use and disclose information about you. We also describe your rights to the health information we keep about you, and describe certain obligations we have regarding the use and disclosure of your health information.</p>
    <p>We are required by law to:</p>
    <ul>
        <li>Make sure that health information that identifies you is kept private</li>
        <li>Give you this Notice of our legal duties and privacy practices with respect to health information about you</li>
        <li>Follow the terms of the Notice that is currently in effect</li>
    </ul>
    <p>How we may use and disclose health information about you:</p>
    <ul>
        <li>For Treatment</li>
        <li>For Payment</li>
        <li>For Healthcare operations</li>
        <li>For appointment reminders</li>
        <li>As required by law</li>
        <li>Public Health risks</li>
        <li>Health oversight activities</li>
        <li>Lawsuits and disputes</li>
        <li>Law enforcement</li>
        <li>To avert a serious threat to health and safety</li>
        <li>As required by the Military or Veterans and Workers Compensation</li>
        <li>Coroners, health examiners, and funeral directors</li>
        <li>National Security and Intelligence activities</li>
        <li>Protective Services for the President and others</li>
        <li>Security Officials for Inmates</li>
        <li>For any services provided by Luxe Mobile IV</li>
    </ul>
    <p>Your rights regarding Health Information about you:</p>
    <ul>
        <li>Right to inspect and copy</li>
        <li>Right to Amend</li>
        <li>Right to Accounting of Disclosures</li>
        <li>Right to Request Restrictions</li>
        <li>Right to Request Confidential Communication</li>
    </ul>
    <p>Your Medical Records: The original copy of your and/or electronic medical record is the property of Luxe Mobile IV. You may request a copy of your records to be transferred by completing a medical records release form. As allowed by Texas state law, there will be a fee for providing you with this service. We require 14 business days from the date of your request to prepare and send your records unless the records are for urgent of life-threatening health issues.</p>
    <p>Luxe Mobile IV- Phone: 832-977-1855 - Website: theluxemobile.com</p>
    <p>Changes to this Notice: We reserve the right to change this Notice. We will post a copy of the current notice in our facility with the current effective date.</p>
    <p>Complaints: If you believe that your privacy rights have been violated, you may file a complaint with us. All complaints must be in writing. Please contact the administrator at the location where you were treated to file a complaint. For complete, detailed information regarding privacy laws, visit www.cms.gov/hipaa For complete, detailed information regarding privacy laws, visit www.cms.gov/hipaa Permission to Share your Health Information: We are required to follow certain federal guidelines and laws regarding the confidentiality of your personal health information. One of these prevents us from discussing anything in your medical file with anyone other than yourself or other medical personnel involved in your care.</p>
    <p>Acknowledgement of Receipt of the Luxe Mobile IV HIPAA NOTICE OF PRIVACY PRACTICES:</p>
    <p>We request that you sign this form acknowledging you have received, read, and reviewed the Luxe Mobile IV HIPAA Notice of Privacy Practices. If the patient is a minor, the legal guardian is automatically appointed by law to provide/receive protected information on behalf of the patient. I will notify Luxe Mobile staff of any changes or updates to this record. This acknowledgement will become part of your records.</p>
    <div/>
`
const state = {
  node: {
    type: 'Content',
    treeId: 1,
    node: 1,
    is_root: '0',
    node_name: '',
    page_title: 'Patient Agreement',
    content: agreement,
    question: '',
    x: '510',
    y: '259',
    jsmessage: '',
    messages: {},
    buttons: {
      0: {
        op: '',
        rank: '1',
        value: '0',
        updated: '0',
        hover_text: '',
        button_data: '0',
        button_link: '2',
        button_text: 'I agree with the terms of this waiver.',
        wizard_skip: '0',
        project_node_id: '2',
        logic_expression: ''
      },
    },
  },
  formData: { agreement },
  formFields: {
  },
  nodesToGoBack: 1,
  renderedContent: '',
  renderedButtons: '',
  renderedForm: '',
  sessionId: '',
};

module.exports = state;
