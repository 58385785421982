import React from 'react';

const GoogleMapsLink = ({ bookingData }) => {
  // booking zip code is defined and of proper length
  if (bookingData.zipcode) {
    // Format the address parts
    const addressString = `${bookingData.address1}, ${bookingData.city}, ${bookingData.state} ${bookingData.zipcode}`;

    // Create the Google Maps URL with the formatted address
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressString)}`;

    return (
        <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
          View on Google Maps
        </a>
    );
  } else {
    // Format the address parts
    const addressString = `${bookingData.address1}, ${bookingData.city}, ${bookingData.state}`;

    // Create the Google Maps URL with the formatted address
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(addressString)}`;

    return (
        <a href={googleMapsUrl} target="_blank" rel="noopener noreferrer">
          View on Google Maps
        </a>
    );
  }
}

export default GoogleMapsLink;
