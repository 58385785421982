import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../adminStyles.css';

const InventoryHome = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [destinationLocation, setDestinationLocation] = useState('');
  const [transactionStatus, setTransactionStatus] = useState(null);
  // const [supplies, setSupplies] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [user, setUser] = useState('');
  const [transactionType, setTransactionType] = useState('snapshot');
  const navigate = useNavigate(); // Use useNavigate hook

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user)
          return data.user
          // console.log('user info from first useEffect:', data.user, user)
          // fetchWorkSchedule(currentDate); // Fetch work schedules for the initial date on page load
        } else {
          window.location.href = '/login';
        }
      }).then(async rsp => {
        const locationsData = await fetchInventoryLocations();
        // sort fetchInventoryLocations by description in ascending order
        locationsData.sort((a, b) => a.name.localeCompare(b.name));

        setLocations(locationsData);
        setSelectedLocation(locationsData[0]?.uuid);
        // console.log('locations:', locationsData, 'user:', user)
        const defaultLocation = await locationsData.find(location => location.staffId === rsp.id);
        // console.log('default location:', defaultLocation)
        // setDestinationLocation(locationsData[0]?.uuid);
        // console.log(defaultLocation, 'defaultLocation')
        if (defaultLocation) {
          setDestinationLocation(defaultLocation.uuid);
        } else {
          setDestinationLocation(locationsData[0]?.uuid);
        }
      })
  }, []);

  const handleQuantityChange = (id, newValue) => {
    setVendors((prevVendors) =>
      prevVendors.map((vendor) =>
        vendor.id === id ? { ...vendor, transferQuantity: newValue } : vendor
      )
    );
  };

  const handleAdjustment = (id, delta) => {
    setVendors((prevVendors) =>
      prevVendors.map((vendor) =>
        vendor.id === id ? { ...vendor, transferQuantity: vendor.transferQuantity + delta } : vendor
      )
    );
  };

  useEffect(() => {
    if (selectedLocation) {
      (async () => {
        const vendorData = await fetchVendors(selectedLocation);
        setVendors(vendorData);
      })();
    }
  }, [selectedLocation]);

  // Mock function to simulate fetching inventory locations from an API endpoint
  const fetchInventoryLocations = async () => {
    try {
      const response = await fetch('/api/admin/getInventoryLocationsExpress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      // console.log('data', data)

      return data.map(location => ({
        uuid: location.uuid,
        staffId: location.staffId,
        name: location.description,
        type: location.type
      }));
    } catch (error) {
      console.error('Error fetching inventory locations:', error);
      return []; // Return an empty array in case of error
    }
  };

  const fetchVendors = async (locationUuid) => {
    try {
      const response = await fetch('/api/admin/getSuppliesExpress', {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const suppliesData = await response.json();
      // console.log('supplies response', suppliesData);

      const formattedVendors = []
      suppliesData.map(ea => {
        return ea.approvedVendors.map(vendor => {
          return formattedVendors.push({
            id: vendor.uuid,
            supplyName: ea.name,
            vendorName: vendor.name,
            packageType: vendor.packageType,
            supplyInfo: ea,
            distributor: vendor.distributor,
            mgPerMl: vendor.mgPerMl,
            // unit: ea.unitOfMeasure,
            // units: ea.units,
            ml: vendor.mlPerPackage,
            transferQuantity: 0,
            unitsPerPackage: vendor.unitsPerPackage,
            vendors: ea.approvedVendors,
            vendorDescription: vendor.description,
          });
        })
      });

      return formattedVendors; // Return the formatted supplies data
    } catch (error) {
      console.error('Error fetching supplies:', error);
      return []; // Return an empty array in case of error
    }
  };

  // Mock function to simulate a transaction action (transfer/purchase)
  const performTransaction = async () => {
    setTransactionStatus('pending');

    const reportingStaffId = user && user.id;

    let allTransactionsSuccessful = true;

    for (const vendor of vendors) {
      if (vendor.transferQuantity > 0) {
        const transactionData = {
          supplyUuid: vendor.supplyInfo.uuid,
          type: transactionType,
          quantity: vendor.transferQuantity * vendor.unitsPerPackage,
          fromInventoryLocationUuid: (transactionType === 'transfer' ? selectedLocation : null),
          toInventoryLocationUuid: destinationLocation,
          reportingStaffId,
          vendorUuid: vendor.id,
          bookingUuid: null,
        };

        try {
          const response = await fetch('/api/admin/createInventoryTransaction', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(transactionData)
          });

          const data = await response.json();
          console.log('Transaction response:', data);

          if (!response.ok) {
            allTransactionsSuccessful = false;
          }
        } catch (error) {
          console.error('Error in transaction:', error);
          allTransactionsSuccessful = false;
        }
      }
    }

    setTimeout(() => {
      setTransactionStatus(allTransactionsSuccessful ? 'success' : 'error');

      if (allTransactionsSuccessful) {
        // Reset form data/numbers
        setVendors((prevVendors) =>
          prevVendors.map((vendor) => ({ ...vendor, transferQuantity: 0 }))
        );

        setTimeout(() => {
          setTransactionStatus(null);
        }, 1000);
      }
    }, 2000);
  }

  // const handleVialChange = (id, delta) => {
  //   setSupplies((prevSupplies) =>
  //     prevSupplies.map((supply) =>
  //       supply.id === id ? { ...supply, vials: supply.vials + delta } : supply
  //     )
  //   );
  // };

  return (
    <div style={{ padding: '5px', fontFamily: 'Arial, sans-serif' }} className= "inventoryPage">
      {/* Inventory location selection */}
      {/** display a warning to users if screen is less than 400 px wide to rotate screen */}
      <div className="rotateScreen">
        <p>Rotate your screen to landscape for better viewing</p>
      </div>
      <div style={{ marginBottom: '20px' }}>
        {transactionType === 'transfer' && (
          <label style={{ marginRight: '10px' }}>
            Origin inventory location:
            <select
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              style={{ marginLeft: '10px', padding: '5px', fontSize: '16px' }}
            >
              {locations.map((location) => (
                <option key={location.uuid} value={location.uuid}>
                  {location.name}
                </option>
              ))}
            </select>
          </label>
        )}
        {/* If transaction type "Transfer" show option to define Destination Location */}
        <label style={{ marginRight: '10px' }}>
          Destination location:
          <select
            value={destinationLocation}
            disabled={user.acl < 4}
            onChange={(e) => setDestinationLocation(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px', fontSize: '16px' }}
          >
            {locations.map((location) => (
              <option key={location.uuid + '2'} value={location.uuid}>
                {location.name}
              </option>
            ))}
          </select>
        </label>
        <label style={{ marginRight: '10px' }}>
          Transaction Type:
          <select
            value={transactionType}
            disabled={user.acl < 4}
            onChange={(e) => setTransactionType(e.target.value)}
            style={{ marginLeft: '10px', padding: '5px', fontSize: '16px' }}
          >
            <option value="snapshot">Snap Shot</option>
            <option value="transfer">Transfer</option>
            <option value="purchase">Purchase</option>
          </select>
        </label>
        <br/>
        <button
          onClick={performTransaction}
          disabled={transactionStatus === 'pending'}
          style={{
            padding: '5px 20px',
            fontSize: '16px',
            margin: '10px 0',
            backgroundColor: (() => {
              if (transactionStatus === null) {
                return 'lightblue';
              } else if (transactionStatus === 'pending') {
                return 'grey';
              } else if (transactionStatus === 'success') {
                return 'green';
              } else if (transactionStatus === 'error') {
                return 'red';
              } else {
                return 'initial';
              }
            })(),
            color:(() => {
              if (transactionStatus === null) {
                return 'black';
              } else if (transactionStatus === 'pending') {
                return 'grey';
              } else if (transactionStatus === 'success') {
                return 'green';
              } else if (transactionStatus === 'error') {
                return 'red';
              } else {
                return 'initial';
              }
            })(),
          }}
        >
          Send
        </button>
        <p className='todo'> <b> Instructions: </b> 
        Enter quantity for each line. Click send.
        If button turns green, information was saved. For partial vials, enter partial numbers (0.1 for 10% left).</p>
      </div>
      {/* Supplies table */}
      <div style={{ marginBottom: '20px', overflowX: 'auto' }}>
      <table>
        <thead>
          <tr>
            {/* <th>Item</th> */}
            <th>Vendor's Name</th>
            <th>Distributor</th>
            <th>
              mL per <br />
              package
            </th>
            <th>mg / mL</th>
            <th>Package <br/> Type</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {vendors.map((item) => (
            <tr key={item.id}>
              {/* <td>{item.supplyName}</td> */}
              <td>{item.vendorName}</td>
              <td>{item.distributor}</td>
              <td>{item.ml}</td>
              <td>{item.mgPerMl}</td>
              <td>{item.packageType}</td>
              <td>
                <input
                  type="number"
                  value={item.transferQuantity !== null ? item.transferQuantity : 0.0}
                  onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                />
                <br />
                <button onClick={() => handleAdjustment(item.id, 0.1)}>+</button>
                <button onClick={() => handleAdjustment(item.id, -0.1)}>-</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <button onClick={() => navigate('/admin/inventoryHome')}>Back</button> <br />
    </div>
  );
};

export default InventoryHome;
