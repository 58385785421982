const state = {
  node: {
    type: 'Content',
    treeId: 8,
    node: 1,
    is_root: '0',
    node_name: '',
    page_title: 'Intro',
    content:
    `<div>
    <h2 style="text-align: center;">Membership Agreement</h2>
    <h1>Luxe Mobile IV RGV - Membership Terms and Auto-Draft Consent</h1>
    <h2>Membership Agreement and Auto-Draft Consent Form</h2>
    <p>This document is intended to serve as the Membership Agreement and Auto-Draft Consent for Luxe Mobile IV RGV's membership program.</p>
    <p>I have been informed of and understand the terms of the membership program offered by Luxe Mobile IV RGV. I acknowledge that I am entering into a minimum three-month consecutive membership and authorize automatic drafts from my debit card for payment.</p>
    <p>I understand that:</p>
    <ol>
        <li>The membership requires a minimum commitment of three (3) consecutive months.</li>
        <li>My debit card will be automatically charged each month for the duration of my membership.</li>
        <li>After the initial three-month period, my membership will continue on a month-to-month basis unless cancelled.</li>
        <li>Cancellation requires written notice and is subject to the terms outlined in this agreement.</li>
    </ol>
    <p>I understand that:</p>
    <ol>
        <li>The membership fee will be automatically drafted from my provided debit card each month.</li>
        <li>It is my responsibility to ensure that my debit card information is current and valid.</li>
        <li>Failure to update my payment information may result in suspension of membership benefits.</li>
        <li>Luxe Mobile IV RGV reserves the right to adjust membership fees with 30 days' notice.</li>
    </ol>
    <p>I am aware that other unforeseeable changes to the membership program could occur. I do not expect Luxe Mobile IV RGV to anticipate and explain all possible future modifications to the program. I understand the terms and benefits of the membership and have had the opportunity to have all of my questions answered.</p>
    <p>My signature below confirms that:</p>
    <ol>
        <li>I understand the information provided on this form and agree to all statements made above.</li>
        <li>The Membership Agreement and Auto-Draft Consent have been adequately explained to me.</li>
        <li>I have received all the information and explanation I desire concerning the membership.</li>
        <li>I authorize and consent to the automatic drafting of my debit card for membership fees.</li>
        <li>I commit to a minimum of three (3) consecutive months of membership.</li>
    </ol>
    <h2>Cancellation Policy</h2>
    <p>I understand that:</p>
    <ul>
        <li>I may not cancel my membership during the initial three-month period except in cases of proven hardship, as determined by Luxe Mobile IV RGV management.</li>
        <li>After the initial three-month period, I may cancel my membership by providing written notice at least 30 days before my next billing date.</li>
        <li>Luxe Mobile IV RGV reserves the right to terminate memberships at their discretion, with appropriate notice.</li>
    </ul>
    <h2>Auto-Draft Authorization</h2>
    <p>I authorize Luxe Mobile IV RGV to initiate automatic debit entries to my designated debit card for the agreed-upon monthly membership fee. This authorization will remain in effect until I cancel it in writing, and I agree to notify Luxe Mobile IV RGV of any changes in my account information or termination of this authorization at least 15 days prior to the next billing date.</p>
    <p>Changes to this Agreement: We reserve the right to change this Agreement. We will notify members of any significant changes via the contact information provided.</p>
    <p>Acknowledgement of Receipt and Agreement:</p>
    <p>I acknowledge that I have received, read, and agree to the terms outlined in this Luxe Mobile IV RGV Membership Agreement and Auto-Draft Consent Form. I understand that this agreement will become part of my records with Luxe Mobile IV RGV.</p>
    </div>
`,
    question: '',
    x: '510',
    y: '259',
    jsmessage: '',
    messages: {},
    buttons: {
      0: {
        op: '',
        rank: '1',
        value: '0',
        updated: '0',
        hover_text: '',
        button_data: '0',
        button_link: '2',
        button_text: 'I agree with the terms of this waiver.',
        wizard_skip: '0',
        project_node_id: '2',
        logic_expression: ''
      },
    },
  },
  formData: {},
  formFields: {
  },
  nodesToGoBack: 1,
  renderedContent: '',
  renderedButtons: '',
  renderedForm: '',
  sessionId: '',
};

module.exports = state;
