import React from 'react';

const SocialProofBanner = () => {
  return (
    <div className="social-proof-banner">
      <p style={{ fontWeight: '300' }}>GLP-1 FEATURED IN MORE THAN 3,000 ARTICLES <br/>Find out why they are wildly popular.</p>
      <div className="star-rating" style={{ display: 'block' }}>
        <div className="" style={{ display: 'block' }}>
          <img src={require('../../../../images/insider.webp')} className='networkIcon' alt="Gold star" style={{ width: '85px', height: '85px' }}/>
          <img src={require('../../../../images/forbes.webp')} className='networkIcon' alt="Gold star" style={{ width: '85px', height: '85px' }}/>
          <img src={require('../../../../images/fox.webp')} className='networkIcon' alt="Gold star" style={{ width: '85px', height: '85px' }}/>
          <img src={require('../../../../images/nyt.webp')} className='networkIcon' alt="Gold star" style={{ width: '85px', height: '85px' }}/>
          <img src={require('../../../../images/rollingstones.webp')} className='networkIcon' alt="Gold star" style={{ width: '85px', height: '85px' }}/>
        </div>
      </div>
    </div>
  );
};

export default SocialProofBanner;
