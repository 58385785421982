import React, { useState } from 'react';
import './Tabs.css';

const TabContainer = ({ children, changeMade, setChangeMade }) => {
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const handleClick = (e, newActiveTab) => {
    e.preventDefault();

    // Check if there are unsaved changes
    if (changeMade) {
      // Show confirmation dialog
      const proceed = window.confirm("You have unsaved changes. Press 'OK' to proceed without saving, or 'Cancel' to go back and save your changes.");

      // If user does not want to proceed, stop here
      if (!proceed) {
        return;
      } else {
        setChangeMade(false)
        window.location.reload();
      }
    }

    // If user decides to proceed or there are no changes, switch tabs
    setActiveTab(newActiveTab);
  };
  return (
    <div>
      <ul className="tab-list">
        {children.map((child) => {
          const { label } = child.props;

          return (
            <li
              key={label}
              className={label === activeTab ? 'tab-list-item tab-list-active' : 'tab-list-item'}
              onClick={(e) => handleClick(e, label)}
            >
              {label}
            </li>
          );
        })}
      </ul>
      <div className="tab-content">
        {children.map((child) => {
          if (child.props.label !== activeTab) return undefined;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

export default TabContainer;
