import { React, useEffect, useState } from 'react';
import Footer from '../generalComponents/footer';
import Navbar from '../generalComponents/Navbar';
import './master.css';
const VisitComplete = (props) => {
  const [textDisabled, setTextDisabled] = useState(false);

  return (
    <>
    <Navbar/>
    <div style={{ height: '100vh', textAlign: 'center', paddingTop:"100px" }}>
      <div className="mt-5 mx-4 text-center" style={{ fontSize: '30px', paddingLeft: '5vw', paddingRight: '5vw' }}> <b style={{ fontWeight: '500' }}>
      <br/>
       Thank you for using Luxe Mobile IV.</b>
      <br/>
      </div>
      <p className="mt-5 mx-4" style={{ fontSize: '20px', minHeight: '20vh', paddingLeft: '5vw', paddingRight: '5vw', fontWeight: '300' }}>If you felt that there was some way your experience today could have been improved, or you have questions or concerns about your visit, please contact us at<br/> <a href="tel:+18329797034">(832) 979-7034.</a>
      </p>
      <Footer/>
    </div>
    </>
  );
};

export default VisitComplete;
