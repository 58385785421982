import React from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import TreeRenderer from '../tree/TreeRenderer';
import './ExitSurvey.css';
import './master.css';
import Footer from '../generalComponents/footer';
import Navbar from '../generalComponents/Navbar';

const ExitSurveyPage = () => {
  const { bookingUUID } = useParams();
  console.log('bookingUuid', bookingUUID);
  // Get all cookies as an object
  const allCookies = Cookies.get();

  return (
    <>
      <Navbar />
      <div className="exit-survey-page" id="exitSurvey" style={{paddingTop:"100px"}}>
        {/* <h1>Exit Survey</h1> */}
        <TreeRenderer
          treeName="ExitSurvey"
          bookingUuid={bookingUUID}
          bookingData={{
            uuid: bookingUUID,
            cookies: allCookies
          }}
        />
      </div>
      <Footer />
    </>
  );
};

export default ExitSurveyPage;
