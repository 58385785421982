import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import './adminStyles.css';
const AdminMonthlySchedule = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [workSchedules, setWorkSchedules] = useState([]);
  const [user, setUser] = useState('');
  const { cityId } = useParams();
  const [loadingMessage, setLoadingMessage] = useState('Loading...');

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setUser(data.user);
          fetchWorkSchedule(currentMonth);
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  const fetchWorkSchedule = async (newMonth) => {
    const startDate = newMonth.clone().startOf('month');
    const endDate = newMonth.clone().endOf('month');
    // const schedules = [];
    const workScheduleResponse = await fetch('/api/admin/getWorkScheduleByInputRangeOverview', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        city: cityId,
      }),
    });
    const schedules = await workScheduleResponse.json();

    // for (let date = startDate; date.isSameOrBefore(endDate); date.add(1, 'day')) {
    //   const response = await fetch('/api/admin/getWorkScheduleOverview', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       date: date.format('YYYY-MM-DD'),
    //       city: cityId,
    //     }),
    //   });
    //   const data = await response.json();
    //   // console.log(data)
    //   schedules.push(...data);
    // }

    setLoadingMessage(null)

    // console.log('Fetched work schedules:', schedules); // Console log fetched work schedules
    setWorkSchedules(schedules);
  };

  const increaseMonth = () => {
    const newMonth = currentMonth.clone().add(1, 'month');
    setCurrentMonth(newMonth);
    setLoadingMessage('Loading...');
    fetchWorkSchedule(newMonth);
  };

  const decreaseMonth = () => {
    const newMonth = currentMonth.clone().subtract(1, 'month');
    setLoadingMessage('Loading...');
    setCurrentMonth(newMonth);
    fetchWorkSchedule(newMonth);
  };

  const getStaffCountInWindow = (date, startHour, endHour) => {
    // console.log(`Checking staff count for ${date.format('YYYY-MM-DD')} (${startHour}-${endHour})`);
    // console.log(workSchedules);
    const staffCount = workSchedules.reduce((count, schedule) => {
      const scheduleDate = moment(schedule.startTime).format('YYYY-MM-DD');
      const scheduleStartTime = moment(schedule.startTime);
      const scheduleEndTime = moment(schedule.endTime);
      const windowStartTime = moment(date).hour(startHour).startOf('hour');
      const windowEndTime = moment(date).hour(endHour).startOf('hour');

      const isMatchingDate = scheduleDate === date.format('YYYY-MM-DD');
      const isOverlappingTime =
        scheduleStartTime.isBefore(windowEndTime) &&
        scheduleEndTime.isAfter(windowStartTime) &&
        !(scheduleStartTime.isSame(windowEndTime) || scheduleEndTime.isSame(windowStartTime));

      if (isMatchingDate) {
        // console.log(scheduleStartTime);
        // console.log(scheduleEndTime);
        // console.log(windowStartTime);
        // console.log(windowEndTime);
        // console.log(date);
        // console.log(`Schedule: ${schedule.id}`);
        // console.log(`Date: ${scheduleDate}, Start Time: ${scheduleStartTime.format('HH:mm')}, End Time: ${scheduleEndTime.format('HH:mm')}`);
        // console.log(`Matching Date: ${isMatchingDate}, Overlapping Time: ${isOverlappingTime}`);
      }

      return isMatchingDate && isOverlappingTime ? count + 1 : count;
    }, 0);

    // console.log(`Total Staff Count: ${staffCount}`);

    return staffCount;
  };

  const getCellColor = (count, target) => {
    const percentage = count / target;
    if (percentage < 0.75) return 'green';
    if (percentage >= 0.75 && percentage < 1) return 'lightgreen';
    if (percentage === 1) return 'lightblue';
    if (percentage > 1 && user.acl > requiredACL) return 'red';
    if (percentage > 1) return 'lightblue';
  };

  const requiredACL = 5

  const getStaffTarget = (date, timeSlot) => {
    const isFriOrSat = (date.day() === 5 || date.day() === 6);
    const isTuesday = (date.day() === 2);
    switch (timeSlot) {
      case 'morning':
        return isFriOrSat ? 2 : 2;
      case 'lateMorning':
        return isFriOrSat ? 6 : (isTuesday ? 4 : 5);
      case 'afternoon':
        return isFriOrSat ? 6 : (isTuesday ? 4 : 5);
      case 'earlyEvening':
        return isFriOrSat ? 6 : (isTuesday ? 4 : 5);
      case 'lateEvening1':
        return isFriOrSat ? 6 : (isTuesday ? 4 : 5);
      case 'lateEvening2':
        return isFriOrSat ? 2 : 1;
      default:
        return 0;
    }
  };

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  const daysInMonth = currentMonth.daysInMonth();
  const dates = Array.from({ length: daysInMonth }, (_, i) => currentMonth.clone().date(i + 1));

  //   console.log('Current month:', currentMonth.format('MMMM YYYY')); // Console log current month

  return (
    <div className="adminParent monthlySchedule">
      <h1 className='todo' style={{ textAlign: 'center' }}>{loadingMessage}</h1>
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
    <button onClick={decreaseMonth} style={{ marginRight: '10px' }}>-</button>
    <h2 style={{ margin: '0', fontSize: '24px', fontWeight: 'bold' }}>{currentMonth.format('MMMM YYYY')}</h2>
    <button onClick={increaseMonth} style={{ marginLeft: '10px' }}>+</button>
  </div>
  <h3 style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}>Monthly Schedule View</h3>
  <table style={{ padding: '20px', maxWidth: '1200px', margin: 'auto' }}>
    <thead>
      <tr>
        <th>Date</th>
        <th>Morning<br />(7am-9am)</th>
        <th>Late Morning<br />(9am-12pm)</th>
        <th>Afternoon<br />(12pm-3pm)</th>
        <th>Early Evening<br />(3pm-6pm)</th>
        <th>Mid Evening<br />(6pm-8pm)</th>
        <th>Late Evening<br />(8pm-10pm)</th>
      </tr>
    </thead>
    <tbody>
      {dates.map(date => (
        <tr key={date.format('YYYY-MM-DD')}>
          <td>{date.format('MM/DD ddd')}</td>
          <td style={{ textAlign: 'center', backgroundColor: getCellColor(getStaffCountInWindow(date, 7, 9), getStaffTarget(date, 'morning')) }} >
            {user.acl > requiredACL && getStaffCountInWindow(date, 7, 9)}
          </td>
          <td style={{ textAlign: 'center', backgroundColor: getCellColor(getStaffCountInWindow(date, 9, 12), getStaffTarget(date, 'lateMorning')) }}>
          {user.acl > requiredACL && getStaffCountInWindow(date, 9, 12)}
          </td>
          <td style={{ textAlign: 'center', backgroundColor: getCellColor(getStaffCountInWindow(date, 12, 15), getStaffTarget(date, 'afternoon')) }}>
          {user.acl > requiredACL && getStaffCountInWindow(date, 12, 15)}
          </td>
          <td style={{ textAlign: 'center', backgroundColor: getCellColor(getStaffCountInWindow(date, 15, 18), getStaffTarget(date, 'earlyEvening')) }}>
          {user.acl > requiredACL && getStaffCountInWindow(date, 15, 18)}
          </td>
          <td style={{ textAlign: 'center', backgroundColor: getCellColor(getStaffCountInWindow(date, 18, 20), getStaffTarget(date, 'lateEvening1')) }}>
          {user.acl > requiredACL && getStaffCountInWindow(date, 18, 20)}
          </td>
          <td style={{ textAlign: 'center', backgroundColor: getCellColor(getStaffCountInWindow(date, 20, 22), getStaffTarget(date, 'lateEvening2')) }}>
          {user.acl > requiredACL && getStaffCountInWindow(date, 20, 22)}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: 'green', marginRight: '5px' }}></div>
          <span>Shifts Available</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: 'lightgreen', marginRight: '5px' }}></div>
          <span>Near Capacity</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: 'lightblue', marginRight: '5px' }}></div>
          <span>No Shifts Available</span>
        </div>
        {user.acl > requiredACL && <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: 'red', marginRight: '5px' }}></div>
          <span>Overstaffed</span>
        </div>}
  </div>

  <div className="calendar-bottom-space">
    <button onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
      Back
    </button>
  </div>
</div>
  );
};

export default AdminMonthlySchedule;
