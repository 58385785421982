import * as React from 'react'
import { useEffect } from 'react'

function Seo (props) {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           title
  //           description
  //           author
  //         }
  //       }
  //     }
  //   `
  // )

  useEffect(() => {
    // const script = document.createElement('script');
    // script.src = "//scripts.iconnode.com/115913.js";
    // document.body.appendChild(script);

    // Optional: Clean up the script when the component unmounts
    return () => {
      // document.body.removeChild(script);
    };
  }, []);

  const metaDescription = props.context && props.context.seoDescription || 'metaDescription' // site.siteMetadata.description
  const defaultTitle = 'title' // site.siteMetadata?.title || "Title"
  return (
    <>
      <title>{props.context && props.context.seoTitle ? `${props.context.seoTitle}` : defaultTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={props.context ? `${props.context.seoTitle}` : defaultTitle}/>
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={ 'twitterCreator'} />
      <meta name="twitter:title" content={props.context ? `${props.context.seoTitle}` : defaultTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <link rel="canonical" href={'https://www.luxemobileiv.com/' + (props.context && props.context.name == 'homepage' ? '' : props.context.name + '/')} />
      <meta
        name="viewport"
        content="height=device-height,
                      width=device-width, initial-scale=1.0,
                      minimum-scale=1.0, maximum-scale=5.0,
                      target-densitydpi=device-dpi"
      />
    </>
  )
}

export default Seo
