import React, { useState, useEffect } from 'react'
import Footer from '../General/footer.js'
import NavBar from '../General/navbar.js'
import Intro from './components/IntroWeight.js'
import SocialProof from './components/SocialProofWeight.js'
import Reviews from './components/Reviews.js'
import Team from './components/Team.js'
import Seo from '../General/seo.js'
import JSONData from './lpvariants.json';
import AdCookie from '../General/AdCookie.js'
import './master.css';

const homePageData = JSONData.lpVariants.find(variant => variant.name === 'homepage');

const HomePage = () => {
  const [services, setServices] = useState([]);
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    // Fetch image data
    const fetchImageData = async () => {
      // Replace this with your actual image loading logic
      // For example, you might use an API call or import images directly
      const aishaImage = await import('../../../images/aisha.webp');
      const rosinesImage = await import('../../../images/rosines.webp');
      const treatmentImages = await fetchTreatmentImages();

      setImageData({
        aishaImage,
        rosinesImage,
        allTreatmentImages: treatmentImages
      });
    };

    fetchImageData();

    // Fetch services if needed
    // const fetchServices = async () => {
    //   try {
    //     const response = await fetch('/api/getServices', {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({}),
    //     });
    //     const data = await response.json();
    //     setServices(data);
    //   } catch (error) {
    //     console.error('Error fetching services:', error);
    //   }
    // };
    // fetchServices();
  }, []);

  const getTreatmentsWithImages = () => {
    if (!imageData) return [];

    const imageFluidMap = imageData.allTreatmentImages.reduce((acc, image) => {
      acc[image.relativePath] = image;
      return acc;
    }, {});

    return homePageData.treatmentsData.map(treatment => {
      const lookupKey = `treatments/${treatment.imageUrl}`;
      const imageFluid = imageFluidMap[lookupKey];
      return {
        ...treatment,
        imageFluid: imageFluid || null,
      };
    });
  };

  const teamMembers = {
    section: {
      title: 'MEET THE TEAM',
      subtitle: 'Expert care from experienced medical professionals.',
    },
    members: [
      {
        image: imageData?.aishaImage,
        title: 'Nurse & Co-founder',
        name: 'Aisha Mashwani',
        description: 'RN. Treating more than 25,000 patients in Emergency Rooms and surgery centers before starting Luxe Mobile IV, Aisha is known for giving the most pain free IVs in Houston and regularly services big name musicians when in Houston. Now she offers services to all Houstonians.',
      },
      {
        image: imageData?.rosinesImage,
        title: 'MD & Co-founder',
        name: 'Dr. Noam Rosines',
        description: 'Dr. Rosines, MD, Baylor College of Medicine. Medical Director of 7 Houston area emergency rooms. Board certified.',
      },
    ],
  };

  if (!imageData) return null; // Or a loading spinner

  return (
    <>
      <Seo context={homePageData}/>
      <NavBar pageContext={homePageData}/>
      <AdCookie />
      <Intro pageContext={homePageData} />
      <SocialProof pageContext={homePageData}/>
      <Reviews pageContext={homePageData}/>
      <Team teamMembers={teamMembers} pageContext={homePageData}/>
      <Footer pageContext={homePageData}/>
    </>
  )
}

export default HomePage

// Helper function to fetch treatment images (replace with your actual image loading logic)
async function fetchTreatmentImages () {
  // This is a placeholder. You'll need to implement your own image loading logic here.
  // It might involve importing images or fetching them from an API
  return [];
}
