import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../generalComponents/footer';
import Navbar from '../generalComponents/Navbar';
import './ExitSurvey.css';
import './master.css';
import '../tree/Trees.css';
import {
  // Button,
  Col,
  Container,
  // Fa,
  // Input,
  Row,
  Mask,
} from 'mdbreact';

const ThankYou = () => {
  const { cityId: urlCityId } = useParams();
  const [reviewLinks, setReviewLinks] = useState({
    google: '',
    yelp: '',
    facebook: ''
  });

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const queryCityId = params.get('cityId');

    // Use the cityId from URL params if available, otherwise use from query params
    const cityId = urlCityId || queryCityId;

    // Define review links for each city
    const cityReviewLinks = {
      1: {
        google: 'https://g.page/r/CW6-TLtM7hhBEB0/review',
        yelp: 'https://www.yelp.com/writeareview/biz/2IGhxlzJYNK6Z0xHWlIt-A?return_url=%2Fbiz%2F2IGhxlzJYNK6Z0xHWlIt-A&review_origin=biz-details-war-button',
        facebook: 'https://www.facebook.com/luxemobileiiv/reviews'
      },
      2: {
        google: 'https://g.page/r/CcQj6kC4HfCKEBM/review',
        yelp: 'https://www.yelp.com/writeareview/biz/2IGhxlzJYNK6Z0xHWlIt-A?return_url=%2Fbiz%2F2IGhxlzJYNK6Z0xHWlIt-A&review_origin=biz-details-war-button',
        facebook: 'https://www.facebook.com/luxemobileiiv/reviews'
      },
      4: {
        google: 'https://g.page/r/CWi3ACpGHlG0EBM/review',
        yelp: 'https://www.yelp.com/writeareview/biz/7Jv6lf5ea0qmgaCKOOThjA?return_url=%2Fbiz%2F7Jv6lf5ea0qmgaCKOOThjA&review_origin=biz-details-war-button',
        facebook: 'https://www.facebook.com/profile.php?id=100069697836722&sk=reviews'
      },
    };

    // Set the review links based on the cityId
    // console.log(cityReviewLinks)
    // console.log(cityId)
    // console.log(cityReviewLinks[cityId])
    setReviewLinks(cityReviewLinks[cityId] || cityReviewLinks['1']); // Default to city 1 if cityId is not found
  }, [location, urlCityId]);

  const handleReviewClick = (platform) => {
    window.dataLayer.push({ event: `${platform}_REVIEW` });
  };

  return (
    <>
      <Navbar />
        <div className="exit-survey-page" id="exitSurvey" style={{ paddingTop: '100px' }}>
        <div id="newrequesttrees">
        <Mask className="d-flex justify-content-center responsive-align align-items-center white">
          {/* <Container fluid className="my-0 mx-5">
            <Row className="align-items-center mt-5" >
            <Col className="mx-0 px-0 mb-3"> */}
              <p>Thank you for using Luxe Mobile IV! <br/>
                  <strong>Would you take a moment to share your thoughts by leaving a review?</strong>
              </p>
              <div className='well' style={{ maxWidth: '95vw' }}>
                <div className='w-20 tree-btn-container white'>
                  <button
                    className="tree-btn"
                    onClick={() => {
                      handleReviewClick('G');
                      window.location.href = reviewLinks.google; // Redirect to the Google review link
                    }}
                  >
                    Leave a Google Review!
                  </button>
                </div>
                <div className='w-20 tree-btn-container'>
                  <button
                    className="tree-btn"
                    onClick={() => {
                      handleReviewClick('Y');
                      window.location.href = reviewLinks.yelp; // Redirect to the Yelp review link
                    }}
                  >
                    Leave a Yelp Review!
                  </button>
                </div>

                <div className='w-20 tree-btn-container'>
                  <button
                    className="tree-btn"
                    onClick={() => {
                      handleReviewClick('F');
                      window.location.href = reviewLinks.facebook; // Redirect to the Facebook review link
                    }}
                  >
                    Leave a Facebook Review!
                  </button>
                </div>
              </div>
              {/* </Col>

            </Row>
          </Container> */}
          </Mask>
          </div>
        </div>
      <Footer />
    </>
  );
};

export default ThankYou;
