import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import './adminStyles.css';

const ViewSchedule = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [workSchedules, setWorkSchedules] = useState({});
  const [filteredWorkSchedules, setFilteredWorkSchedules] = useState({});
  const [user, setUser] = useState(null);
  const { cityId } = useParams();
  const [loadingMessage, setLoadingMessage] = useState('Loading...');
  const [listOfStaff, setListOfStaff] = useState([]);

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          // console.log(data);
          setIsAuthenticated(true);
          setUser(data.user);
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  useEffect(() => {
    if (user) {
      fetchWorkSchedule(currentMonth);
    }
  }, [user, currentMonth]);

  const fetchWorkSchedule = async (month) => {
    setLoadingMessage('Loading...');
    const startDate = month.clone().startOf('month');
    const endDate = month.clone().endOf('month');
    const schedules = {};
    const workScheduleResponse = await fetch('/api/admin/getWorkScheduleByInputRangeOverview', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
        city: cityId,
      }),
    });
    let schedulesData = await workScheduleResponse.json();
    // console.log(schedulesData)
    const aclLimit = 4
    if (user.acl < aclLimit) {
      schedulesData = schedulesData.filter(schedule => schedule.staffId === user.id);
    }

    for (let date = startDate.clone(); date.isSameOrBefore(endDate); date.add(1, 'day')) {
      schedules[date.format('YYYY-MM-DD')] = schedulesData.filter(
        schedule => (
          (moment(schedule.startTime) > moment(date.format('YYYY-MM-DD'))) && 
          (moment(schedule.startTime) < moment(date).endOf('day'))
        )
      )
    }

    // set the list of staff as all of the unique staff in the schedules data
    const staffList = schedulesData.map(schedule => schedule.staff);
    const uniqueStaffList = Array.from(new Map(staffList.map(staff => [staff.id, staff])).values());
    setListOfStaff(uniqueStaffList)
    setWorkSchedules(schedules);
    setFilteredWorkSchedules(schedules);
    setLoadingMessage(null);
  };

  const increaseMonth = () => {
    setCurrentMonth(prev => prev.clone().add(1, 'month'));
  };

  const decreaseMonth = () => {
    setCurrentMonth(prev => prev.clone().subtract(1, 'month'));
  };

  if (!isAuthenticated || !user) {
    return <div>Loading...</div>;
  }

  const setSelectedStaff = (staffId) => {
    if (staffId === 'all') {
      setFilteredWorkSchedules(workSchedules);
    } else {
      const filteredSchedules = {};
      // console.log('work schedules', workSchedules)
      // consol
      for (const date in workSchedules) {
        // console.log('date', date, workSchedules[date], staffId)
        filteredSchedules[date] = workSchedules[date].filter(schedule => {
          // console.log('schedule', schedule.staffId, staffId, schedule.staffId === staffId)
          return Number(schedule.staffId) === Number(staffId)
        }
        );
      }
      setFilteredWorkSchedules(filteredSchedules);
    }
  };

  const renderCalendar = () => {
    const startDate = currentMonth.clone().startOf('month');
    const endDate = currentMonth.clone().endOf('month');
    const calendar = [];

    // Add weekday headers
    const weekdays = moment.weekdaysShort();
    weekdays.forEach(day => {
      calendar.push(
        <div key={`header-${day}`} className="calendar-header">
          {day}
        </div>
      );
    });

    // Fill in empty cells for days before the start of the month
    const firstDayOfMonth = startDate.day();
    for (let i = 0; i < firstDayOfMonth; i++) {
      calendar.push(<div key={`empty-start-${i}`} className="calendar-day empty"></div>);
    }

    for (let day = startDate.clone(); day.isSameOrBefore(endDate); day.add(1, 'day')) {
      const formattedDate = day.format('YYYY-MM-DD');
      const daySchedules = filteredWorkSchedules[formattedDate] || [];

      calendar.push(
        <div key={formattedDate} className="calendar-day">
          <div className="date">
            <span className="day-number">{day.format('D')} - </span>
            <span className="day-name">{day.format('ddd')}</span>
          </div>
          <div className="schedules">
            {daySchedules.map((schedule, index) => (
              <div key={index} className="schedule-item" style={{backgroundColor: schedule.staff.extraStuff?.color}}>
                {schedule.staff.firstName} {schedule.staff.lastName}: {moment(schedule.startTime).format('h:mma')} - {moment(schedule.endTime).format('h:mma')}
                <hr/>
              </div>
            ))}
          </div>
        </div>
      );
    }

    // Fill in empty cells for days after the end of the month
    const lastDayOfMonth = endDate.day();
    for (let i = lastDayOfMonth; i < 6; i++) {
      calendar.push(<div key={`empty-end-${i}`} className="calendar-day empty"></div>);
    }

    return calendar;
  };

  return (
    <div className="adminParent monthlySchedule">
      <h1 className='todo' style={{ textAlign: 'center' }}>{loadingMessage}</h1>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
        <button onClick={decreaseMonth} style={{ marginRight: '10px' }}>-</button>
        <h2 style={{ margin: '0', fontSize: '24px', fontWeight: 'bold' }}>{currentMonth.format('MMMM YYYY')}</h2>
        <button onClick={increaseMonth} style={{ marginLeft: '10px' }}>+</button>
      </div>
      <h3 style={{ textAlign: 'center', fontSize: '20px', marginBottom: '10px' }}>
        {user.acl < 4 ? 'Your Monthly Schedule' : 'Staff Monthly Schedule'}
      </h3>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <label htmlFor="staffSelect" style={{ marginRight: '10px' }}>Select Staff:</label>
        <select id="staffSelect" onChange={(e) => setSelectedStaff(e.target.value)}>
          <option value="all">All</option>
          {listOfStaff.map(staff => (
            <option key={staff.id} value={staff.id}>
              {staff.firstName} {staff.lastName}
            </option>
          ))}
        </select>
      </div>
      <div className="calendar">
        {renderCalendar()}
      </div>
      <div className="calendar-bottom-space">
        <button onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
          Back
        </button>
      </div>
    </div>
  );
};

export default ViewSchedule;