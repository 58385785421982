import React, { useState, useEffect } from 'react';
// import moment from 'moment';
import { formatDate } from '../utility/module.js';

const WeightLossContent = ({ bookingData, handleFieldChange }) => {
  const [localBookingData, setLocalBookingData] = useState(bookingData);
  const [adminNotes, setAdminNotes] = useState(bookingData.adminNotes || []);
  const [adminNoteText, setAdminNoteText] = useState('');
  const [user, setUser] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState(bookingData.extraStuff.deliveryMethod ? bookingData.extraStuff.deliveryMethod : '');

  useEffect(() => {
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user)
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  const renderAdminNoteSection = () => {
    if (user.acl > 2) {
      return (
        <div className='adminTextField'>
          <h3>Admin Notes</h3>
            Only viewable to admins. Use for notes that are not customer service issues.
            <br /> Note: <br/>
            {adminNotes.length > 0 && (
              <div className='adminTextField'>
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Note</th>
                    <th>Author</th>
                  </tr>
                </thead>
                <tbody>
                  {adminNotes.map((note, index) => (
                    <tr key={index}>
                      <td>{formatDate(note.date)}</td>
                      <td>{note.text}</td>
                      <td>{note.author}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            )}
          <textarea
            value={adminNoteText}
            onChange={(e) => setAdminNoteText(e.target.value)}
            rows={4}
          />
          <br/>
          <button onClick={handleAdminNoteSubmit}>Save Admin Note</button>
        </div>
      );
    }
    return null;
  };

  const handleVerification = () => {
    const updatedExtraStuff = {
      ...localBookingData.extraStuff,
      verified: true,
      verifiedBy: user.email,
      verifiedDate: new Date().toISOString()
    };

    const updatedLocalBookingData = {
      ...localBookingData,
      extraStuff: updatedExtraStuff
    };

    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        extraStuff: updatedExtraStuff
      })
    })
      .then(response => response.json())
      .then(data => {
        setLocalBookingData(updatedLocalBookingData);
      })
      .catch(error => {
        console.error('Error updating booking:', error);
      });
  };

  const handlePickup = () => {
    const updatedExtraStuff = {
      ...localBookingData.extraStuff,
      shippingStatus: 'Complete',
      shippedBy: user.email,
      shippedDate: new Date().toISOString(),
      deliveryMethod
    };

    let updatedLocalBookingData = {
      ...localBookingData,
      extraStuff: updatedExtraStuff
    };

    updatedLocalBookingData.status = 'paidWLComplete'

    if (deliveryMethod !== '') {
      fetch('/api/admin/updateBooking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uuid: localBookingData.uuid,
          status: 'paidWLComplete',
          extraStuff: updatedExtraStuff
        })
      })
        .then(response => response.json())
        .then(data => {
          setLocalBookingData(updatedLocalBookingData);
          // refresh the page
          window.location.reload()
        })
        .catch(error => {
          console.error('Error updating booking:', error);
        });
    } else {
      // send window alert that shipping method must be confirmed
      window.alert('you must select a delivery method first')
    }
  };

  const handleAdminNoteSubmit = () => {
    const newAdminNote = {
      date: new Date().toISOString(),
      text: adminNoteText,
      author: user.email // Assuming the user object contains an email field
      // Add other fields as needed, such as reason, if applicable
    };
    const updatedAdminNotes = [...adminNotes, newAdminNote];

    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: localBookingData.uuid,
        adminNotes: updatedAdminNotes
      })
    })
      .then(response => response.json())
      .then(data => {
        setAdminNotes(updatedAdminNotes); // Update local state with new list of notes
        setAdminNoteText(''); // Clear admin note input field
      })
      .catch(error => {
        console.error('Error updating admin notes:', error);
      });
  };

  return (
    <div className='adminParent adminTabSection'>
      <br/>
      <b>Delivery Type:</b> {localBookingData.extraStuff.deliveryType}<br/>
      <br/>

      <div className='adminTextField'>
        <h3>Verification</h3>
        <i>Documenting verification that the patient has seen the doctor. </i><br/>
        <br/>
        <b>Prescription Verified:</b> {localBookingData.extraStuff.verified ? <span> True </span> : <span> False</span>}<br/>
        <b>Verfied By:</b> {localBookingData.extraStuff.verifiedBy ? <span> {localBookingData.extraStuff.verifiedBy} </span> : <span> Not Verified</span>}<br/>
        <b>Verified Date:</b> {localBookingData.extraStuff.verifiedDate ? <span> {localBookingData.extraStuff.verifiedDate} </span> : <span> Not Verified</span>}<br/>
        <button onClick ={() => handleVerification()}>Mark Verfied</button> <br />
      </div>
      <br />
      <div className='adminTextField'>
        <h3>Shipping / Pickup</h3>
        <label>
          <b>Select shipping method: </b>
          <select
            value={deliveryMethod}
            onChange={(e) => setDeliveryMethod(e.target.value)}>
              <option value=''>Select</option>
              <option value='shippedUPS'>Shipped through UPS</option>
              <option value='pickedup'>Picked Up</option>
              <option value='notshipped'>Dropped off</option>
              <option value='shippedEmpower'>Shipped Through Empower</option>
            </select> <br />
        </label>
        <b>Shipping / Pickup Status: </b> {localBookingData.extraStuff.shippingStatus ? <span> {localBookingData.extraStuff.shippingStatus} </span> : <span> Not Shipped </span>}<br/>
        <b>Marked As Shipped / Picked Up By: </b> {localBookingData.extraStuff.shippedBy ? <span> {localBookingData.extraStuff.shippedBy} </span> : <span> </span>}<br/>
        <b>Shipped / Picked Up Date: </b> {localBookingData.extraStuff.shippedDate ? <span> {localBookingData.extraStuff.shippedDate} </span> : <span> </span>}<br/>
        <button onClick = {() => handlePickup()}>Mark Shipped / Picked Up</button> <br />
      </div>
      <br />
      {renderAdminNoteSection()}
    </div>
  )
}

export default WeightLossContent;
