import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TreeSessionViewer.css';
import SessionDetails from './SessionDetails.js';
import RenderSessionSummary from './SessionSummary.js';

const TreeSessionViewer = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState('');
  const [trees, setTrees] = useState([]);
  const [selectedTree, setSelectedTree] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [nodeVisits, setNodeVisits] = useState({});
  const [nodeResponses, setNodeResponses] = useState({});
  const [searchField, setSearchField] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [bookingUuidSearch, setBookingUuidSearch] = useState('');
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [nodesMap, setNodesMap] = useState({});
  const [formFields, setFormFields] = useState([]);
  const [startDate, setStartDate] = useState(getDefaultStartDate());
  const [endDate, setEndDate] = useState(getTodayDate());
  const [nodeTimings, setNodeTimings] = useState({});

  function getDefaultStartDate () {
    const date = new Date();
    date.setDate(date.getDate() - 3);
    return date.toISOString().split('T')[0];
  }

  function getTodayDate () {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  }

  useEffect(() => {
    // Check user's authentication status when component mounts
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setUser(data.user);
          return data.user;
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      })
      .then((rsp) => {
        axios.post('/api/admin/getTrees').then(response => {
          setTrees(response.data);
        });
      });
  }, []);

  useEffect(() => {
    if (selectedTree) {
      fetchTreeData();
    }
  }, [selectedTree, startDate, endDate]);

  const fetchTreeData = () => {
    axios.post('/api/getAllNodes', { tree: selectedTree })
      .then(response => {
        const nodes = response.data;
        const nodeMap = nodes.reduce((acc, node) => {
          acc[node.node] = node.title || `Node ${node.node}`;
          return acc;
        }, {});
        setNodesMap(nodeMap);

        fetch(`/api/admin/getTreeSessions/${selectedTree}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            startDate,
            endDate
          })
        })
          .then(async response => {
            // const dataIn = await response.json();
            // console.log("getTreeSessoins", dataIn)
            let sessionsData = await response.json()
            sessionsData = sessionsData.map(session => ({
              ...session,
              path: session.session.path,
              formData: session.data
            }));
            // console.log('sessionsData', sessionsData);

            setSessions(sessionsData);
            setFilteredSessions(sessionsData);
            calculateSummaryData(sessionsData, nodeMap);
            extractFormFields(sessionsData);
          });
      });
  };

  const calculateSummaryData = (sessionsData, nodeMap) => {
    const visits = {};
    const responses = {};
    const timings = {};

    sessionsData.forEach(session => {
      session.path.forEach(step => {
        const nodeName = nodeMap[step.fromNodeId] || `Node ${step.fromNodeId}`;
        visits[nodeName] = (visits[nodeName] || 0) + 1;

        if (step.buttonText) {
          responses[nodeName] = responses[nodeName] || {};
          responses[nodeName][step.buttonText] = (responses[nodeName][step.buttonText] || 0) + 1;
        }

        timings[nodeName] = timings[nodeName] || [];
        timings[nodeName].push(step.seconds);
      });
    });

    const calculatedTimings = {};
    Object.entries(timings).forEach(([nodeName, times]) => {
      times.sort((a, b) => a - b);
      const mean = times.reduce((sum, time) => sum + time, 0) / times.length;
      const median = times.length % 2 === 0
        ? (times[times.length / 2 - 1] + times[times.length / 2]) / 2
        : times[Math.floor(times.length / 2)];

      calculatedTimings[nodeName] = { mean, median };
    });

    setNodeVisits(visits);
    setNodeResponses(responses);
    setNodeTimings(calculatedTimings);
  };

  const extractFormFields = (sessionsData) => {
    const fields = new Set();
    sessionsData.forEach(session => {
      Object.keys(session.formData).forEach(field => fields.add(field));
    });
    setFormFields(Array.from(fields));
  };

  const searchSessions = () => {
    const filtered = sessions.filter(session => {
      const fieldMatch = !searchField || !searchValue ||
        (session.formData[searchField] && session.formData[searchField].toString().toLowerCase().includes(searchValue.toLowerCase()));
      const bookingUuidMatch = bookingUuidSearch &&
        session.bookingUuid && session.bookingUuid.toLowerCase().includes(bookingUuidSearch.toLowerCase());
      console.log('bookingUuidMatch', bookingUuidMatch);
      console.log('bookingUuidSearch', bookingUuidSearch);
      console.log('session.bookingUuid', session.bookingUuid);
      // If bookingUuidSearch is provided, prioritize it. Otherwise, use fieldMatch
      return bookingUuidSearch ? bookingUuidMatch : fieldMatch;
    });
    setFilteredSessions(filtered);
  };

  const renderSummaryData = () => (
    <div>
        <h3>Node Visit Summary</h3>
        {Object.entries(nodeVisits).map(([nodeName, count]) => (
            <><div key={nodeName}>
                {nodeName}: {count} visits
                {nodeTimings[nodeName] && (
                    <span>
                        , Mean time: {nodeTimings[nodeName].mean.toFixed(2)} seconds
                        , Median time: {nodeTimings[nodeName].median.toFixed(2)} seconds
                    </span>
                )}
            </div>
            <br/></>
        ))}
        <h3>Node Response Summary</h3>
        {Object.entries(nodeResponses).map(([nodeName, responses]) => (
            <div key={nodeName}>
                <h4>{nodeName}</h4>
                {Object.entries(responses).map(([response, count]) => (
                    <div key={response}>{response}: {count}</div>
                ))}
            </div>
        ))}
    </div>
  );

  return (
    <div className="tree-session-viewer">
      <h1>Tree Session Viewer</h1>
      <p className="todo" style={{ textAlign: 'center', fontWeight: '900' }}>
        DANGER. DANGER. DANGER. <br />
        Do not use during peak hours. It will crash the app. <br />
        Responses limited to 300 sessions. <br />
      </p>
      <select onChange={(e) => setSelectedTree(e.target.value)}>
        <option value="">Select a tree</option>
        {trees.map(tree => (
          <option key={tree.id} value={tree.id}>{tree.treeName}</option>
        ))}
      </select>
      {selectedTree && (
        <>
          <div className="date-range-container">
            <input
              type="date"
              className="date-input"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <input
              type="date"
              className="date-input"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>

          <div className="summary-container">
            <h2>Summary for Tree {selectedTree}</h2>
            {renderSummaryData()}
          </div>

          <h2>Search Sessions</h2>
          <div className="search-container">
            <select
              value={searchField}
              onChange={(e) => setSearchField(e.target.value)}
            >
              <option value="">Select a form field</option>
              {formFields.map(field => (
                <option key={field} value={field}>{field}</option>
              ))}
            </select>

            <input
              type="text"
              className="search-input"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Form field value"
            />
            Or:
            <input
              type="text"
              className="search-input"
              value={bookingUuidSearch}
              onChange={(e) => setBookingUuidSearch(e.target.value)}
              placeholder="Booking UUID"
            />
            <button className="search-button" onClick={searchSessions}>Search</button>
          </div>

          <h2>Filtered Sessions</h2>
          {filteredSessions.map(treeSession => (
            <div className="session-card" key={treeSession.treeSessionUuid}>
              <RenderSessionSummary
                treeSession={treeSession}
              />
              <SessionDetails 
                treeSession={treeSession}
                nodesMap={nodesMap}
              />
            </div>
          ))}
        </>
      )}
      <br />
      <button onClick={() => navigate('/admin')}>Admin Home</button> <br />
    </div>
  );
};

export default TreeSessionViewer;
