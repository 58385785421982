import React, { Component, lazy, Suspense } from 'react';
import { Routes } from 'react-router-dom';
import routes from '../router/routes';
// import SEO from './Utilities/Seo';
// import Footer from './Generic Sections/Footer.js';
// import Navbar from './Generic Sections/Navbar.js';

class Main extends Component {
  render() {

    return (
      <div className="App">
        <header className="App-header">
          {/* <Suspense fallback={renderLoader()}> */}
          {/* <SEO description={"Pavlov Media: Nationwide broadband & TV services. Quality internet for urban & rural areas. Explore our plans today!"}/> */}
          {/* <Navbar/> */}
          <Routes>{routes}</Routes>
          {/* <Footer/> */}
          {/* </Suspense> */}
        </header>
      </div>
    );
  }
}

export default Main;
