import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from './generalComponents/NavbarAdmin.js';
function Login () {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    // Check if user is already authenticated on mount !
    axios.get('/api/auth/status').then(response => {
      if (response.data.authenticated) {
        // Redirect to the admin home area if the user is already authenticated
        window.location.href = '/admin';
      }
    });
  }, []);

  async function handleLogin () {
    window.location.href = `${process.env.REACT_APP_AUTHORIZE_BASEURL}/api/auth/start`;

    // const response = await fetch("http://localhost:3001/api/auth/status");
    // const authStatus = await response.json();

    // if (authStatus.authenticated) {
    //   // User is already authenticated, redirect to the appropriate page
    //   window.location.href = "/dashboard";
    // } else {
    //   // User is not authenticated, start the authentication process
    //   window.location.href = "http://localhost:3001/api/auth/start";
    // }
  };

  return (
    <>
    <Navbar/>
    <div className="login-container adminParent" style={{ textAlign: 'center', marginTop: '70px' }}>
      <br/>
      <br/>
      <h2>Employee Portal</h2>
      <p>Please log in to access the admin features.</p>
      <button onClick={handleLogin}>Login with Microsoft</button>
    </div>
    </>
  );
}

export default Login;
