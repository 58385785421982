import React, { useEffect, useState, useRef } from 'react';
// import { CookiesProvider, withCookies } from 'react-cookie';
import {
  Button,
  Container,
  Mask,
  Modal,
  ModalHeader,
  ModalBody,
} from 'mdbreact';
import { useNavigate, useLocation } from 'react-router-dom';
import { v4 } from 'uuid';
import formDifferentiation from './FormType.js';
import './Trees.css';
// import imageCompression from 'browser-image-compression';
// import moment from 'moment';
// import AdCookie from '../Utilities/AdCookie.js';
import Node from './sections/Node';

const trees = {
  PTIVChart: require('./sections/PTIVChart'),
  HTXWaiver: require('./sections/HTXWaiver'),
  RGVMembership: require('./sections/RGVMembership'),
  SemaglutideTirzMDHP: require('./sections/SemaglutideTirzMDHP'),
  SemaglutidePatientAgreement: require('./sections/SemaglutidePatientAgreement'),
  SemaglutideMedicalChart: require('./sections/SemaglutideMedicalChart'),
  ExitSurvey: require('./sections/ExitSurvey'),
  GlpCheckoutPage: require('./sections/GlpCheckoutPage')
};

/*
To do:
* Pass in the locations information as a URL parameter.
* Add IP address into the formData object in useEffect hook.
* See if the approximate location is also available in the formData object.
* check to see how the backend handles the formData object. Line 109 may need to add in the formData object to exiting formData object.
*/
const TreeRenderer = (props) => {
  // console.log('props in Tree Renderer, line 37:', props)
  const navigate = useNavigate();
  const location = useLocation();

  const tree = [props.treeName];
  const sigCanvasRef = useRef(null);

  // console.log(tree)
  const startState = [tree];

  // Define state variables
  const [node, setNode] = useState(trees[startState].node);
  // console.log(node)
  const [currentTree, setCurrentTree] = useState(trees[startState]);
  const [startingTree, setStartingTree] = useState(startState);
  const [nodeHistory, setNodeHistory] = useState([]);
  const [modal, setModal] = useState(false);
  const [waitingOnBackEndResponse, setWaitingOnBackEndResponse] = useState(false);
  const [renderedForm, setRenderedForm] = useState(startState.formFields);
  const [renderedButtons, setRenderedButtons] = useState(trees[startState].node.renderedButtons);
  const [sessionId, setSessionId] = useState();
  const [formData, setFormData] = useState({}); // Initialize with your default form data
  const [formFields, setFormFields] = useState({}); // Initialize with your default form fields

  useEffect(() => {
    window.scroll(0, 0);
    // const locationJson = { hh: '4d57920b-551c-4848-9c42-603e095ba2ae', ro: 'dad6886d-68a9-435d-97ed-2806fd5333f1' };

    // Update state
    const newFormData = {
      ...currentTree.formData,
    };

    // Now, use the setters to update the state
    // setRenderedButtons(node.renderedButtons);
    // setRenderedForm(startingTree.formFields);
    setSessionId(v4());
    setFormData(newFormData);
    // if (props.bookingData && props.bookingData.cookies) {
    //   setFormData(oldData => ({ ...oldData, cookies: props.bookingData.cookies }));
    // }
    // console.log(currentTree)
    // Event listener for 'popstate'
    const handlePopState = (e) => {
      e.preventDefault();
      getPreviousNode();
    };

    window.addEventListener('popstate', handlePopState);

    // Cleanup function
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const addToButtonsInContent = newButtons => {
    this.setState({
      buttonsInContent: 'Something',
    });
  };

  const clearSignature = () => {
    if (sigCanvasRef.current) {
      sigCanvasRef.current.clear();
    }
  };
  const saveSignature = () => {
    if (sigCanvasRef.current) {
      const signature = sigCanvasRef.current.toDataURL();
      setFormData({
        signature
      });
      // console.log('formdata', formData)
      setWaitingOnBackEndResponse(true); // Update the state to indicate loading
      const bodyJson = {
        // patientId, // Assume patientId is defined in your state
        formData: { signature, complete: true },
        session: sessionId, // Assume sessionId is defined in your state
        tree: currentTree.node.treeId, // Update according to your state structure
        node: currentTree.node.node, // Update according to your state structure
        button: 0,
        link: currentTree.link, // Update according to your state structure
        bookingUuid: props.bookingData.uuid
      };
      fetch('/api/getNextNode/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyJson),
      })
        .then(response => response.json())
        .then(rsp => {
          // console.log('fetch api response', rsp);
          if (rsp.link !== null) {
            // add in logic for links: mark session complete, create booking, navigate to checkout with booking uuid

            navigate({
              pathname: rsp.link,
              state: { url: location.pathname }
            });
          }
          // Update state with the response
          // console.log('formData', formData)
          // console.log('formFields', formFields)
          // const newRenderedButtons = buttonRender(rsp.buttons);
          // const newRenderedForm = formRender();
          setFormData({});
          // setRenderedForm(rsp.formFields);
          // setFormFields(rsp.formFields);

          // setRenderedButtons(newRenderedButtons);
          // setRenderedForm(newRenderedForm);
          // setCurrentTree(rsp);
          // setNode(rsp)
          setWaitingOnBackEndResponse(false);
          // console.log('formData', formData)
          // console.log('formFields', formFields)
          props.closeModal();
          window.scroll(0, 0);
        });

      // fetch('/api/submit/', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(),
      // })
      //   .then(response => response.json())
      //   .then(rsp => {})
    }
  };
  function getOrderedUuids (nodeHistory, formData) {
    const serviceUuidArray = [];

    // Reverse the array to start from the most recent node
    const reversedHistory = [...nodeHistory].reverse();

    for (const item of reversedHistory) {
      // console.log(item)
      if (item.node.node === 2 || item.node.node === 22) {
        // We've found the dosage selection node
        // const selectedButton = item.selectedButton;
        const selectedService = item.selectedService;
        // const buttonData = item.node.buttons[selectedButton].button_data;

        // if (buttonData) {
        // const quantity = 1; // Default to 1 if quantity is not specified
        // for (let i = 0; i < quantity; i++) {
        // serviceUuidArray.push({ uuid: buttonData });
        serviceUuidArray.push({ uuid: selectedService });
        // }
        // }

        // We've processed the dosage node, so we can break the loop
        break;
      }
    }

    // Check the deliveryType and add the specific service UUID if needed
    // console.log("formData.deliveryType",formData.deliveryType)
    // console.log("formData.deliveryType",formData.deliveryType !== 'pickup')
    if (formData.deliveryType !== 'pickup') {
      // console.log("here")
      serviceUuidArray.push({ uuid: '8b7183bc-62bd-471c-9385-947ab5dbc2b6' });
    }
    // console.log("serviceUuidArray",serviceUuidArray)
    return serviceUuidArray;
  }

  const getNextNode = (selectedButton) => {
    // console.log(formData, 'getNextNode formData');
    // console.log(selectedButton, 'selectedButton');
    // console.log('this is props in getNextNode', props);
    const bodyJson = {
      // patientId, // Assume patientId is defined in your state
      formData,
      session: sessionId, // Assume sessionId is defined in your state
      tree: currentTree.node.treeId, // Update according to your state structure
      node: node.node, // Update according to your state structure
      button: selectedButton,
      link: currentTree.link, // Update according to your state structure
      bookingUuid: props.bookingData.uuid
    };

    const listOfFieldKeys = Object.keys(formFields);
    const listOfFields = listOfFieldKeys.map(eachKey => formFields[eachKey]);
    const requiredFields = listOfFields.filter(eachField => eachField.required == '1');
    const requiredCheck = requiredFields.reduce((total, eachField) => {
      if (formData[eachField.name] == null) {
        return 1 + total;
      }
      return total;
    }, 0);

    if (requiredCheck === 0 && !waitingOnBackEndResponse) {
      // console.log('bodyJson', bodyJson);
      // console.log("selectedButton", selectedButton);
      // console.log("node.buttons[selectedButton].button_data", node.buttons[selectedButton].button_data);
      // console.log("node", node);
      setNodeHistory(prevHistory => [...prevHistory, {
        node,
        formData,
        formFields,
        selectedService: node.buttons[selectedButton].button_data // Store the selected button text

      }]);

      // console.log('here i am', nodeHistory)
      setWaitingOnBackEndResponse(true); // Update the state to indicate loading

      fetch('/api/getNextNode/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodyJson),
      })
        .then(response => response.json())
        .then(rsp => {
          // console.log('here I am')
          // console.log(rsp)

          // console.log('fetch api response', rsp);
          if (rsp.link !== null) {
            // console.log("formData",formData)
            if (rsp.link === 'generated') {
              if (formData.deliveryType === true) {
                formData.deliveryType = 'pickup';
              } else {
                formData.deliveryType = 'delivery';
              }
              const weightLossBodyJson = {
                userInfo: {
                  firstName: formData.firstName?.trim(),
                  lastName: formData.lastName?.trim(),
                  email: formData.email?.trim(),
                  phone: formData.phone?.trim(),
                  address1: formData.address1?.trim(),
                  address2: formData.address2?.trim(),
                  city: formData.city?.trim(),
                  state: formData.state?.trim(),
                  zipcode: formData.zipcode?.trim(),
                  phone: formData.phone?.trim()
                },
                bookingNotes: 'Weight loss booking from online form',
                serviceUuidArray: getOrderedUuids(nodeHistory, formData),
                cityId: 1, // This should be the appropriate city ID
                deliveryType: formData.deliveryType?.trim() // This should be either 'pickup' or 'shipping'
              };
              // console.log('rsp', rsp);
              // console.log('currentTree', currentTree);
              // console.log('props', props);

              fetch('/api/createWeightLossBooking/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(weightLossBodyJson),
              })
                .then(response => response.json())
                .then(createdBookingData => {
                  // Navigate to created link if available.
                  console.log('Booking created:', createdBookingData);
                  const bodyJsonComplete = {
                    // patientId, // Assume patientId is defined in your state
                    session: sessionId, // Assume sessionId is defined in your state
                    // tree: currentTree.node.treeId, // Update according to your state structure
                    // node: currentTree.node.node, // Update according to your state structure
                    // link: currentTree.link, // Update according to your state structure
                    bookingUuid: createdBookingData.bookingInfo.uuid,
                  };
                  fetch('/api/finishedWeightlossCheckout/', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(bodyJsonComplete),
                  })
                    .then(response => response.json())
                  window.location.href = createdBookingData.checkoutLink
                })
                .catch(error => {
                  console.error('Error creating booking:', error);
                  // Handle any errors here
                });
            } else {
              navigate({
                pathname: rsp.link,
                state: { url: location.pathname }
              });
            }
          }
          // if (rsp.link !== null) {
          //   navigate({
          //     pathname: rsp.link,
          //     state: { url: location.pathname }
          //   });
          // }
          // Update state with the response
          // console.log('formData', formData)
          // console.log('formFields', formFields)
          const newRenderedButtons = buttonRender(rsp.buttons);
          const newRenderedForm = formRender();
          setFormData({});
          // setRenderedForm(rsp.formFields);
          setFormFields(rsp.formFields);

          // console.log('newRenderedButtons:', newRenderedButtons)
          // console.log('newRenderedForm:', newRenderedForm)
          setRenderedButtons(newRenderedButtons);
          setRenderedForm(newRenderedForm);
          // setCurrentTree(rsp);
          // console.log(rsp);
          setNode(rsp)
          setWaitingOnBackEndResponse(false);
          // console.log('formData', formData)
          // console.log('formFields', formFields)
          window.scroll(0, 0);
        });

      // navigate(`${location.pathname}`);
    } else {
      setModal(!modal);
    }
  };

  const getPreviousNode = () => {
    const bodyJson = {
      nodesToGoBack, // Assuming nodesToGoBack is defined in your state
      session: sessionId, // Assuming sessionId is defined in your state
    };

    fetch('/api/getPreviousNode/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodyJson),
    })
      .then(response => response.json())
      .then(rsp => {
      // Update state with the response
        setFormData({});
        setFormFields(rsp.formFields);
        setCurrentTree(rsp); // Assuming you have a setCurrentTree function
        // Assuming you have functions for rendering buttons and forms
        const newRenderedButtons = buttonRender(rsp.buttons); // Update according to your function
        // Update according to your function
        setRenderedButtons(newRenderedButtons);

        setNodesToGoBack(prevNodesToGoBack => prevNodesToGoBack + 1); // Update nodesToGoBack
      });
  };

  useEffect(() => {
    const newRenderedForm = formRender();
    setRenderedForm(newRenderedForm);
  }, [formFields]);

  const buttonRender = (buttonData) => {
    // console.log('buttonData', buttonData)
    const listOfButtonNumbers = Object.keys(buttonData);

    const theButtons = listOfButtonNumbers.map((eachButtonNumber, index) => {
      const reactButtonContent = JSON.stringify(
        buttonData[eachButtonNumber].button_text
      ).replace(/"/g, '');
      const buttonDataAttribute = buttonData[eachButtonNumber].button_data || null;
      // console.log(buttonDataAttribute)
      if (buttonData[eachButtonNumber].button_text.includes('Close Form')) {
        return (
          <div className="w-20 tree-btn-container" key={index}>
            <button className="tree-btn" onClick={() => {
              props.closeModal();
              window.scroll(0, 0);
            }}
            >
              {reactButtonContent}
            </button>
          </div>
        );
      } else {
        return (
          <div className="w-20 tree-btn-container" key={index}>
            <button className="tree-btn" onClick={() => getNextNode(eachButtonNumber)}
            data-button-data={buttonDataAttribute}
            >
              {reactButtonContent}
            </button>
          </div>
        );
      }
    });

    return theButtons;
  };

  const onFormInputChange = (e) => {
    const { type, checked, id, value, name, files } = e.target;

    if (type === 'checkbox') {
      setFormData(prevFormData => { return ({ ...prevFormData, [name]: checked }) });
      // console.log(formData)
    } else if (type === 'radio') {
      setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
    } else if (type === 'file') {
      const uploadAsyncImage = async () => {
        const fileData = new FormData();
        const uniqueFile = files[0];
        // Append additional data if necessary
        fileData.append('title', id);
        fileData.append('file', uniqueFile); // Assuming you want to upload the original file

        // Handle file compression and upload here
        // ...

        const response = await fetch('/api/uploadImage/', {
          method: 'POST',
          body: fileData,
        });
        const rsp = await response.json();
        // Handle the response
        // ...
      };
      uploadAsyncImage();
      setFormData(prevFormData => ({ ...prevFormData, [id]: files[0] })); // Store the file in formData
    } else {
      setFormData(prevFormData => ({ ...prevFormData, [id]: value }));
    }
  };

  const formRender = () => {
    const formFieldList = formFields;
    // console.log('formFieldList', formFieldList)
    const listOfFormFieldsKeys = Object.keys(formFieldList);

    if (listOfFormFieldsKeys.length > 0) {
      return listOfFormFieldsKeys.map((eachFormKey) => {
        // Adjust the formDifferentiation function call as needed.
        // If formDifferentiation relies on 'this', you need to refactor it to work as a standalone function or hook.
        return formDifferentiation(eachFormKey, formFieldList, onFormInputChange, formData, formFields, sigCanvasRef, clearSignature, saveSignature);
      });
    }
  };

  // console.log('node', node)
  const splitContent = node && node.content.split('##data-entry-fields##');
  const reactNodeContent1 = splitContent && splitContent[0];
  const reactNodeContent2 = splitContent && splitContent.length > 1 ? JSON.stringify(splitContent[1]) : null;

  const question = <div className="nodeQuestion">{JSON.stringify(node.question)}</div>;

  return (
      <div id={'newrequesttrees'} style={{ maxWidth: '90vw', minHeight: '60vh' }}>
        <Mask className="d-flex justify-content-center responsive-align text-center white">
          <Container fluid>
            <Modal
              isOpen={modal}
              toggle={() => setModal(!modal)}
              className="text-center"
              centered
            >
              <ModalHeader toggle={() => setModal(!modal)}>Info Required</ModalHeader>
              <ModalBody>
                <strong>You must fill out the required fields to proceed.</strong> <br /> <br />
              </ModalBody>
              <Button color="danger" onClick={() => setModal(!modal)}>Close</Button>
            </Modal>

            <Modal
              isOpen={waitingOnBackEndResponse}
              className="text-center"
              centered
            >
              <ModalBody>
                <span style={{ fontWeight: '900' }}>... Calculating</span> <br />
              </ModalBody>
            </Modal>
            <Node
              reactNodeContent1={reactNodeContent1}
              reactNodeContent2={reactNodeContent2}
              question={question}
              buttons={buttonRender(node.buttons)}
              form={renderedForm}
              treeName={props.treeName}
            />
          </Container>
        </Mask>
      </div>
  );
}

export default TreeRenderer;
