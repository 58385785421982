import React from 'react';
import { Col, } from 'mdbreact';
import SignatureCanvas from 'react-signature-canvas';
import './Trees.css';

export default function formDifferentiation (eachFormKey, formFieldList, onFormInputChange, formData, formFields, sigCanvasRef, clearSignature, saveSignature) {
  // console.log(eachFormKey);
  if (formFieldList[eachFormKey].type === 'multiline' ||
    formFieldList[eachFormKey].type === 'multiline_full') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            {/* {console.log(formFieldList[eachFormKey].name)} */}
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label}
            </label>
            <br />
            <input
              id={formFieldList[eachFormKey].name}
              name={formFieldList[eachFormKey].name}
              onChange={e => onFormInputChange(e)}
              value={formData[formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              autoComplete="on"
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'number') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            {/* {console.log(formFieldList[eachFormKey].name)} */}
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label}
            </label>
            <br />
            <input
              type="number"
              id={formFieldList[eachFormKey].name}
              name={formFieldList[eachFormKey].name}
              onChange={e => { onFormInputChange(e) }}
              value={formData[formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              autoComplete="on"
              min="0"
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'iteratedNumber') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}><button onClick={e => {
            e.preventDefault();
            const currentValue = parseInt(formData[formFields[eachFormKey].name] || '0', 10);
            console.log(currentValue)
            const newValue = currentValue + 1;
            onFormInputChange({
              target: {
                type: 'number',
                id: formFields[eachFormKey].name,
                name: formFields[eachFormKey].name,
                value: newValue.toString()
              }
            });
          }}>
              +
            </button> {' '}
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label}
            </label>
            <br />

            <div style={{ display: 'flex', alignItems: 'center', maxWidth: '500px' }}>
              <input
                type="number"
                id={formFieldList[eachFormKey].name}
                name={formFieldList[eachFormKey].name}
                value={formData[formFields[eachFormKey].name] || 0}
                onChange={e => { console.log(e); onFormInputChange(e) }}
                className="forms"
                style={{
                  width: '40px',
                  height: '30px',
                  textAlign: 'center',
                  border: '1px solid #ccc',
                  borderRadius: '0',
                  padding: '0',
                  margin: '0'
                }}
                disabled
                min="0"
              />

            </div>
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'address') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label}
            </label>
            <br />
            <input
              id={formFieldList[eachFormKey].name}
              name={formFieldList[eachFormKey].name}
              onChange={e => onFormInputChange(e)}
              value={formData[formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              autoComplete="street-address"
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'select') {
    const fieldOptions = formFieldList[eachFormKey].options.split('\n');
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            {/* {formFieldList[eachFormKey].label && <p>{formFieldList[eachFormKey].label}</p>} */}
            <select
              id={formFieldList[eachFormKey].name}
              onChange={e => onFormInputChange(e)}
              value={formData[formFieldList[eachFormKey].name]}
              className="browser-default custom-select"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
            >
              {fieldOptions.map(fieldOption => (
                <option value={fieldOption}>{fieldOption}</option>
              ))}
            </select>
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'text') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block', textAlign: 'left' }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label_type === 'P'
                ? null
                : formFieldList[eachFormKey].label}
            </label>
            {formFieldList[eachFormKey].label_type === 'P' ? null : <br />}
            <input
              id={formFieldList[eachFormKey].name}
              onChange={e => onFormInputChange(e)}
              value={formData[formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              placeholder={
                formFieldList[eachFormKey].label_type === 'P'
                  ? formFieldList[eachFormKey].label
                  : null
              }
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'date') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label_type === 'P'
                ? null
                : formFieldList[eachFormKey].label}
            </label>
            {formFieldList[eachFormKey].label_type === 'P' ? null : <br />}
            <input
              id={formFieldList[eachFormKey].name}
              type="date"
              onChange={e => onFormInputChange(e)}
              value={formData[formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
              placeholder={
                formFieldList[eachFormKey].label_type === 'P'
                  ? formFieldList[eachFormKey].label
                  : null
              }
              autofill="bday"
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'email') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label_type === 'P'
                ? null
                : formFieldList[eachFormKey].label}
            </label>
            {formFieldList[eachFormKey].label_type === 'P' ? null : <br />}
            <input
              id={formFieldList[eachFormKey].name}
              onChange={e => onFormInputChange(e)}
              value={formData[formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              placeholder={
                formFieldList[eachFormKey].label_type === 'P'
                  ? formFieldList[eachFormKey].label
                  : null
              }
              key={formFieldList[eachFormKey].name}
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'phone') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          <div style={{ display: 'block' }}>
            <label
              htmlFor={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
              className="qlabel"
            >
              {formFieldList[eachFormKey].label}
            </label>
            <br />
            <input
              type="number"
              id={formFieldList[eachFormKey].name}
              onChange={e => onFormInputChange(e)}
              value={formData[formFields[eachFormKey].name]}
              className="w-100 forms"
              style={{ maxWidth: '500px' }}
              key={formFieldList[eachFormKey].name}
            /* cols="55" */
            />
          </div>
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'radio') {
    const fieldOptions = formFieldList[eachFormKey].options.split('\n');
    return (
      <div className="formArea" key={eachFormKey}>

        {/* <ul className="likert mx-auto"> */}
        <ul className="" style={{ listStyleType: 'none', padding: '0px', textAlign: 'left' }}>
          <label
            htmlFor={formFieldList[eachFormKey].name}
            id={formFieldList[eachFormKey].name}
            className="qlabel"
          >
            {formFieldList[eachFormKey].label}
          </label>
          {fieldOptions.map(fieldOption => (
            <li key={fieldOption + formFieldList[eachFormKey].name}>
              <input
                type="radio"
                name={formFieldList[eachFormKey].name}
                value={fieldOption}
                onChange={e => onFormInputChange(e)}
                label={JSON.stringify(fieldOption).replace(/"/g, '')}
                id={fieldOption + formFieldList[eachFormKey].name}
              />
              <label style={{ marginLeft: '5px' }} htmlFor={fieldOption + formFieldList[eachFormKey].name}>{fieldOption}</label>

            </li>
          ))}
        </ul>
        {/* <Col size="12">
          {fieldOptions.map(fieldOption => (
            <Input
              style={{ width: "auto", height: "auto" }}
              label={JSON.stringify(fieldOption).replace(/"/g, "")}
              type="radio"
              id={fieldOption + formFieldList[eachFormKey].name}
              key={fieldOption + formFieldList[eachFormKey].name}
              name={formFieldList[eachFormKey].name}
              onChange={e => onFormInputChange(e)}
              value={fieldOption}
            />
          ))}<label
            htmlFor={formFieldList[eachFormKey].name}
            id={formFieldList[eachFormKey].name}
            className="qlabel"
          >
            {JSON.stringify(formFieldList[eachFormKey].label).replace(/"/g, "")}
          </label>
        </Col> */}
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'checkbox') {
    return (
      <div className="formArea" key={eachFormKey}>
        <ul style={{ listStyleType: 'none', padding: '0px', textAlign: 'left' }}>
          <label
            htmlFor={formFieldList[eachFormKey].name}
            id={formFieldList[eachFormKey].name}
            className="qlabel"
          >
            {formFieldList[eachFormKey].label}
          </label>
          {/* <li> */}

          {/*this is a "structural" console log. Inclusion lets the checkbox */}
          {console.log("formFieldList", formFieldList)}
            <input
              type="checkbox"
              name={formFieldList[eachFormKey].name}
              onChange={e => onFormInputChange(e)}
              checked={formData[formFieldList[eachFormKey].name]}
              value={formFieldList[eachFormKey].name}
              id={formFieldList[eachFormKey].name}
            />
            <label
              style={{ marginLeft: '5px' }}
              htmlFor={formFieldList[eachFormKey].name}
            >
              {/* {formFieldList[eachFormKey].label} */}
            </label>
          {/* </li> */}
        </ul>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'upload') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12">
          {`${formFieldList[eachFormKey].label}`}
          <input
            required
            type="file"
            // data-buttonText={`${formFieldList[eachFormKey].label}`}
            name={formFieldList[eachFormKey].name}
            onChange={(e) => onFormInputChange(e)}
            // style={{content:`${formFieldList[eachFormKey].label}`}}
            // label={formFieldList[eachFormKey].label}
            // checked={parentThis.state.formData[formFieldList[eachFormKey].name]}
            // value={formFieldList[eachFormKey].name}
            // className="form-check-input radio-margin"
            id={formFieldList[eachFormKey].name}
            key={formFieldList[eachFormKey].name}
          />
          {/* <button type="submit">Submit</button> */}
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'information') {
    return (
      <div className="formArea" key={eachFormKey}>
        <Col size="12" style={{ padding: '0', margin: '0' }}>
          <br />
          {formFieldList[eachFormKey].label}
        </Col>
      </div>
    );
  } else if (formFieldList[eachFormKey].type === 'signature') {
    return (
      <div className="formArea" key={eachFormKey}>

          <div style={{ display: 'block', width: '100%' }}>
            <label htmlFor={formFieldList[eachFormKey].name} className="qlabel">
              {formFieldList[eachFormKey].label}
            </label>
            <br /> <i>For best experience, rotate phone horizontally</i> <br />
            <br />
            <SignatureCanvas
            ref={sigCanvasRef}
            penColor="black"
            canvasProps={{ className: 'sigCanvas' }}
            /> <br />
            <button onClick={clearSignature}>Clear</button>
            <button onClick={saveSignature}>Save</button>
          </div>
      </div>
    );
  }
}
