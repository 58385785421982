import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './scheduleStyles.css'
import { GoogleMap, LoadScript, Marker, TrafficLayer } from '@react-google-maps/api';
import CustomMarker from './CustomMarker.js'

const containerStyle = {
  width: '100%',
  height: '80vh'
};

const center = {
  lat: 29.7604, // Central latitude for Houston
  lng: -95.3698 // Central longitude for Houston
};

const localizer = momentLocalizer(moment);

const ScheduleMap = ({ userDetails }) => {
  const { cityId } = useParams()
  const [schedule, setSchedule] = useState([]);
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [markers, setMarkers] = useState([]); // State for markers

  useEffect(() => {
    // Check user's authentication status when component mounts
    // This could be a call to your server or checking local storage/session storage for a valid token
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      });
    console.log('fetchSchedule in useEffect')
    fetchSchedule(currentDate);
  }, []);

  useEffect(() => {
    fetchSchedule(currentDate);
  }, [currentDate]);

  useEffect(() => {
    // fetchSchedule(currentDate);
    console.log('markers changed',)
  }, [markers]);
  useEffect(() => {
    fetchSchedule(currentDate);
  }, [currentDate]);

  const fetchSchedule = async (date) => {
    // console.log('fetchSchedule')
    try {
      const response = await fetch('/api/admin/getWorkSchedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date,
          city: cityId,
        }),
      });
      const data = await response.json();

      if (response.ok) {
        // console.log('processing schedule data')
        setSchedule(await processScheduleData(data));
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error fetching work schedule:', error);
    }
  };
    // New function to handle marker addition
  const addMarkers = async () => {
    // console.log('markers', markers)
    setMarkers(createMapMarkers(schedule));
  };

  const processScheduleData = async (data) => {
    // console.log('processScheduleData', data);

    const geocodePromises = [];
    const staffSchedules = {};

    data.forEach(item => {
      const staffName = `${item.staff.firstName} ${item.staff.lastName}`;
      if (!staffSchedules[staffName]) staffSchedules[staffName] = [];

      item.staff.calendarBlocks.forEach(block => {
        const bookingEntry = {
          startTime: moment(block.startTime),
          endTime: moment(block.endTime),
          bookingId: (block.booking ? block.booking.uuid : null),
          title: (item.staff.firstName),
          color: item.staff.extraStuff?.color,
          address: (block.booking ? block.booking.address1 : null),
          city: (block.booking ? block.booking.city : null),
          latitude: null,
          longitude: null
        };

        staffSchedules[staffName].push(bookingEntry);

        if (block.booking && block.booking.address1) {
          geocodePromises.push(geocodeAddress([block.booking.address1, block.booking.city, block.booking.state].join(', ')).then(coords => {
            // console.log(coords)
            if (coords) {
              bookingEntry.latitude = coords.lat;
              bookingEntry.longitude = coords.lng;
            }
          }));
        }
      });
    });

    const newSchedule = await staffSchedules;
    setSchedule(newSchedule); // Set schedule state here after all geocoding is done
    setMarkers(createMapMarkers(newSchedule));
    await Promise.all(geocodePromises);
    return staffSchedules;
  };

  const navigateDay = (direction) => {
    console.log('Navigating day')
    setCurrentDate(prevDate => {
      const newDate = moment(prevDate);
      if (direction === 'forward') {
        newDate.add(1, 'days');
      } else if (direction === 'backward') {
        newDate.subtract(1, 'days');
      }
      return newDate.toDate();
    });
  };
  const geocodeAddress = async (address) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY; // Your Google Maps API key
    // console.log('geocodeAddress', address)
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK') {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        throw new Error(data.status);
      }
    } catch (error) {
      console.error('Error during geocoding:', error);
      return null;
    }
  };

  const createMapMarkers = () => {
    const markers = [];
    // console.log('schedule', schedule)
    console.log('Create Map Markers')
    Object.values(schedule).forEach(staffSchedule => {
      staffSchedule.forEach(booking => {
        console.log('booking', booking)
        if (booking.latitude && booking.longitude) {
          markers.push(
            <CustomMarker
            key={booking.bookingId}
            position={{ lat: booking.latitude, lng: booking.longitude }}
            text={[moment(booking.startTime).format('LT'), booking.title].join('<br/>')} // Format the time as you need
            color={booking.color}
            uuid={booking.bookingId}
          />
          );
        }
      });
    });
    // console.log('markers', markers)
    return markers;
  };

  return (
    <div className="admin-calendar-container">
      <div className='adminPageTitle'>
        <h1>Map block view</h1>
      </div>
      <div className="calendar-navigation">
        <button onClick={() => navigateDay('backward')} className="navigate-button">-</button>
        <span className="current-date-display">{moment(currentDate).format('MMMM Do YYYY')}</span>
        <button onClick={() => navigateDay('forward')} className="navigate-button">+</button>
      </div>
      <button onClick={addMarkers} className="saveButton">
        Update Markers
      </button>
      <div>
        <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
          >
            <TrafficLayer autoUpdate />
            {markers}
          </GoogleMap>
        </LoadScript>
      </div>
      <div className="calendar-bottom-space">
        <button onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
          Back
        </button>
      </div>
    </div>
  );
};

export default ScheduleMap;
