const state = {
  node: {
    type: 'Content',
    treeId: 6,
    node: 2,
    is_root: '0',
    node_name: '',
    page_title: 'Overall Experience',
    content:
      `<p>Thank you for using Luxe Mobile IV.</p>
      <strong>Can you rate your overall experience? </strong></p>`,
    question: '',
    x: '510',
    y: '259',
    jsmessage: '',
    messages: {},
    buttons: {
      0: {
        op: '',
        rank: 1,
        value: 'Excellent',
        updated: 0,
        hover_text: 'Rate as Excellent',
        button_data: 'Excellent',
        button_link: 3,
        button_text: 'Excellent',
        wizard_skip: 0,
        project_node_id: 6,
        logic_expression: ''
      },
      1: {
        op: '',
        rank: 1,
        value: 'Good',
        updated: 0,
        hover_text: 'Rate as Good',
        button_data: 'Good',
        button_link: 13,
        button_text: 'Good',
        wizard_skip: 0,
        project_node_id: 6,
        logic_expression: ''
      },
      2: {
        op: '',
        rank: 1,
        value: 'Average',
        updated: 0,
        hover_text: 'Rate as Average',
        button_data: 'Average',
        button_link: 13,
        button_text: 'Average',
        wizard_skip: 0,
        project_node_id: 6,
        logic_expression: ''
      },
      3: {
        op: '',
        rank: 1,
        value: 'Poor',
        updated: 0,
        hover_text: 'Rate as Poor',
        button_data: 'Poor',
        button_link: 13,
        button_text: 'Poor',
        wizard_skip: 0,
        project_node_id: 6,
        logic_expression: ''
      },
      4: {
        op: '',
        rank: 1,
        value: 'Very Poor',
        updated: 0,
        hover_text: 'Rate as Very Poor',
        button_data: 'Very Poor',
        button_link: 13,
        button_text: 'Very Poor',
        wizard_skip: 0,
        project_node_id: 6,
        logic_expression: ''
      }
    },
  },
  formData: {},
  formFields: {
    0: {
      name: 'overallExperience',
      label: 'How would you rate your overall experience with Luxe Mobile IV?',
      type: 'radio',
      options: 'Excellent\nGood\nAverage\nPoor\nVery Poor',
      required: '1',
    },
    1: {
      name: 'nurseRating',
      label: 'How would you rate the professionalism and care provided by our nurses?',
      type: 'radio',
      options: 'Excellent\nGood\nAverage\nPoor\nVery Poor',
      required: '1',
    },
    2: {
      name: 'bookingProcess',
      label: 'How easy was the booking process?',
      type: 'radio',
      options: 'Very Easy\nEasy\nNeutral\nDifficult\nVery Difficult',
      required: '1',
    },
    3: {
      name: 'recommendService',
      label: 'How likely are you to recommend Luxe Mobile IV to others?',
      type: 'radio',
      options: 'Very Likely\nLikely\nNeutral\nUnlikely\nVery Unlikely',
      required: '1',
    },
    4: {
      name: 'comments',
      label: 'Please provide any additional comments or feedback:',
      type: 'multiline',
      options: '',
      required: '0',
    },
  },
  nodesToGoBack: 1,
  renderedContent: '',
  renderedButtons: '',
  renderedForm: '',
  sessionId: '',
};

module.exports = state;
