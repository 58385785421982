import React from 'react';
import '../adminStyles.css';


const SessionDetails = ({ treeSession, nodesMap }) => {
  const renderSessionDetails = (treeSession) => {
    return (
      <div >
        {/* <p>Form Name: {treeSession.treeName} <br />
        Created At: {new Date(treeSession.createdAt).toLocaleString()} <br />
        Complete: {treeSession.complete ? 'Yes' : 'No'} </p> */}
        <hr />
        <h4>Path Taken:</h4>
        {renderPathOverview(treeSession.session.path)}
        <hr />
        <h4>Data Collected:</h4>
        {renderFormData(treeSession.data)}
      </div>
    );
  }

  const renderPathOverview = (path) => {
    if (!path || !Array.isArray(path)) {
      return <div>No path data available</div>;
    }
    return (
      <div className='adminTable'>
      <table>
      <thead>
        <tr>
        <th>Page Title</th>
        <th>Button <br/>Text</th>
        <th>From <br/> Page<br/> ID</th>
        <th>To <br/> Node<br/> ID</th>
        <th>Seconds</th>
        </tr>
      </thead>
      <tbody>
        {path.map((step, index) => (
        <tr key={index}>
          <td>{step.pageTitle}</td>
          <td>{step.buttonText}</td>
          <td>{nodesMap[step.fromNodeId] || `${step.fromNodeId}`}</td>
          <td>{nodesMap[step.toNodeId] || `${step.toNodeId}`}</td>
          <td>{step.seconds}</td>
        </tr>
        ))}
      </tbody>
      </table>
      </div>
    );
  };

  const renderFormData = (formData) => {
    return Object.entries(formData).map(([key, value]) => {
      if (key === 'signature') {
        return (
          <div key={key}>
            <strong>{key}:</strong> <br />
            <img style={{ maxHeight: '200px' }} src={value} alt="signature" />
          </div>
        );
      } else if (key === 'agreement') {
        const [showMore, setShowMore] = React.useState(false);
        const previewText = value.toString().substring(0, 75); // Show first 20 characters

        return (
          <div key={key}>
            <strong>{key}:</strong> <br />
            {showMore
              ? (<span dangerouslySetInnerHTML={{ __html: value.toString() }} /> )
              : (previewText)}
            {value.toString().length > 75 && (
              <button onClick={() => setShowMore(!showMore)}>
          {showMore ? 'less' : 'more'}
              </button>
            )}
          </div>
        )
      } else {
        return (
          <div key={key}>
            <strong>{key}:</strong> {value.toString()}
          </div>
        );
      }
    });
  };

  return renderSessionDetails(treeSession);
};

export default SessionDetails