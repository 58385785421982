import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { v4 as uuidv4 } from 'uuid';

const SelectBookingsForCheckout = (props) => {
  const navigate = useNavigate(); // Use useNavigate hook
  const { status, cityId } = useParams();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [selectedBookings, setSelectedBookings] = useState(new Set()); // Track selected bookings
  const [groupDiscount, setGroupDiscount] = useState(0);
  const [groupPhone, setGroupPhone] = useState('');
  const [isMultiCheckoutReady, setIsMultiCheckoutReady] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [startDate, setStartDate] = useState(getThreeMonthsAgoDate());
  const [endDate, setEndDate] = useState(getTomorrowDate());
  const [acl, setAcl] = useState(0); // Add ACL to state

  function getThreeMonthsAgoDate() {
    const date = new Date();
    date.setMonth(date.getMonth() - 3);
    return date.toISOString().split('T')[0];
  }

  function getTomorrowDate() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow.toISOString().split('T')[0];
  }

  useEffect(() => {
    const newPhoneNumbers = [...selectedBookings].map(bookingId => {
      return bookings.find(booking => booking.uuid === bookingId)?.phone // Adjust this path as needed
    }
    ).filter((value, index, self) => value && self.indexOf(value) === index); // Remove undefined and duplicate values

    setPhoneNumbers(newPhoneNumbers);
  }, [selectedBookings, bookings]);

  useEffect(() => {
    // Check if more than one booking is selected and required fields are filled
    setIsMultiCheckoutReady(
      selectedBookings.size > 1 && groupPhone !== ''
    );
  }, [selectedBookings, groupPhone, groupDiscount]);

  useEffect(() => {
    // Check user's authentication status when component mounts
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setAcl(data.user.acl)
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  // function to format phone display based on ACL
  const formatPhoneDisplay = (booking) => {
    const { patient, phone } = booking;
    const lastFour = phone.slice(-4);
    if (acl < 3) {
      return `${patient.firstName} ${patient.lastName.charAt(0)}: ${lastFour}`;
    } else {
      return `${patient.firstName} ${patient.lastName.charAt(0)}: ${phone}`;
    }
  };

  function handleSelectBooking (bookingId) {
    setSelectedBookings(prevSelectedBookings => {
      const newSelectedBookings = new Set(prevSelectedBookings);
      if (newSelectedBookings.has(bookingId)) {
        newSelectedBookings.delete(bookingId);
      } else {
        newSelectedBookings.add(bookingId);
      }
      return newSelectedBookings;
    });
  }

  function handleCheckout () {
    if (selectedBookings.size === 1) {
      console.log(selectedBookings)
      const [bookingUuid] = selectedBookings;

      const path = `/checkout/${bookingUuid}`;
      const targetUrl = `${window.location.origin}${path}`;
      fetch('/api/admin/updateBooking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uuid: bookingUuid,
          discount: Number(groupDiscount)
        })
      }).then(rsp => {
        fetch('/api/admin/textInvoice', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            targetUrl,
            groupPhone
          })
        })
          .then(response => response.json())
          .then(data => {
            // console.log(`Updated booking ${bookingUuid} with response:`, data);
            return data;
          })
        navigate(`/checkout/${Array.from(selectedBookings)[0]}`);
      }
      )
    } else {
      const multipleBookingUUID = uuidv4(); // Create a new UUID for multiple bookings

      // Create a promise for each booking update=
      const updatePromises = Array.from(selectedBookings).map(bookingUuid => {
        return fetch('/api/admin/updateBooking', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            uuid: bookingUuid,
            multipleBookingUuid: multipleBookingUUID,
            cityCalendarId: cityId,
            discount: Number(groupDiscount) / Number(selectedBookings.size),
            extraStuff: {}
          })
        })
          .then(response => response.json())
          .then(data => {
            console.log(`Updated booking ${bookingUuid} with response:`, data);
            return data;
          });
      });

      // Wait for all updates to complete
      Promise.all(updatePromises)
        .then(allResponses => {
          const path = `/checkoutMultiple/${multipleBookingUUID}/${groupPhone}`;
          const targetUrl = `${window.location.origin}${path}`;
          fetch('/api/admin/textInvoice', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              multipleBookingUuid: multipleBookingUUID,
              targetUrl,
              groupPhone
            })
          })
            .then(response => response.json())
            .then(data => {
              // console.log(`Updated booking ${bookingUuid} with response:`, data);
              return data;
            });
          console.log('All bookings updated:', allResponses);
          navigate(`/checkoutMultiple/${multipleBookingUUID}/${groupPhone}`);
        })
        .catch(error => {
          console.error('Error updating bookings:', error);
          // Handle errors or notify user as needed
        });
    }
  }

  function formatDate (dateString) {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const date = new Date(dateString);
    // return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', ' at');
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  useEffect(() => {
    if (isAuthenticated) {
      // fetch bookings by status
      const fetchBookingsByStatus = (status) => {
        return fetch('/api/admin/bookingTableView', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status, cityId, startDate, endDate })
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          });
      };

      // Fetch bookings for both statuses
      Promise.all([
        fetchBookingsByStatus('booked'),
        fetchBookingsByStatus('wixImport')
      ])
        .then(([bookedData, wixImportData]) => {
        // Merge the two arrays
          const mergedData = bookedData.concat(wixImportData);

          // Sort the merged array
          mergedData.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

          // Update state with the merged and sorted data
          setBookings(mergedData);
        })
        .catch(error => console.error('Error fetching bookings:', error));
    }
  }, [isAuthenticated, cityId]);

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className='adminParent adminTable'>
      <h1>Bookings By Status</h1>
      <table>
        <thead>
          <tr>
            <th>Start Time</th>
            <th>Staff Name</th>
            <th>Patient Name</th>
            <th>View Booking</th>
            <th>Select for Checkout</th>
          </tr>
        </thead>
        <tbody>
          {bookings.map(booking => (
            <tr key={booking.uuid}>
            {/* {console.log(booking.uuid)} */}
              <td>{formatDate(booking.startTime)}</td>
              <td>{`${booking.staff.firstName} ${booking.staff.lastName}`}</td>
              <td>{booking.patient.firstName} {booking.patient.lastName}</td>

              <td><button onClick={() => navigate(`/admin/bookingNew/${booking.uuid}`)}>view</button><br /></td>
              <td><input
                  type="checkbox"
                  checked={selectedBookings.has(booking.uuid)}
                  onChange={() => handleSelectBooking(booking.uuid)}
                /></td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* { (selectedBookings.size > 1 && !isMultiCheckoutReady) ? <div style={{ color: 'red' }}>Please enter a group email address for handling the invoice/receipt.</div> : <></>} */}
      {selectedBookings.size > 0 && (
  <div>
    <br />
    <label>Phone Number for invoice: </label>
    <select
      value={groupPhone}
      onChange={(e) => setGroupPhone(e.target.value)}
      required
    >
      <option value="">Select a phone number</option>
      {phoneNumbers.map((phoneNumber, index) => {
              const booking = bookings.find(b => b.phone === phoneNumber);
              return (
                <option key={index} value={phoneNumber}>
                  {formatPhoneDisplay(booking)}
                </option>
              );
            })}
    </select><br />
  </div>
      )}
      { (selectedBookings.size > 0)
        ? <div>
        <br/>
          <label><b>Total</b> Group (or individual) Discount: </label>
          <input
            type="number"
            placeholder="Total Discount"
            value={groupDiscount}
            onChange={(e) => setGroupDiscount(e.target.value)}
            required
          />
      </div>
        : <></>}
      <button
        onClick={handleCheckout}
        style={{ marginLeft: '0px' }}
        disabled={selectedBookings.size === 0 || (selectedBookings.size > 1 && !isMultiCheckoutReady) || groupPhone.length < 9}
      >
        Checkout
      </button>
      <div className="calendar-bottom-space">
        <button style={{ marginLeft: '0px' }} onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
          Back
        </button>
      </div>
    </div>
  );
}

export default SelectBookingsForCheckout;
