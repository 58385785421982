import React, { useState, useEffect } from 'react';

const ServicesTable = ({ services, tip, paidAmount, dueAmount, setTip, setTotalAmount, setDueAmount, handleFieldChange, discount, setDiscount, setTipWasSelected }) => {
  const [customTip, setCustomTip] = useState(false);
  const [customDiscount, setCustomDiscount] = useState(discount);

  const formatPrice = (price) => { // used for static selections
    const numericPrice = parseFloat(price);
    return isNaN(numericPrice) ? '0.00' : numericPrice.toFixed(2);
  };

  const formatCurrency = (value) => { // used for active selections, To be implemented
    // const number = parseFloat(value.replace(/[^\d.-]/g, '')) || 0;
    // return number.toFixed(2);
    return value;
  };

  const applyTip = (percentage) => {
    const servicesTotal = services.reduce((sum, item) => sum + parseFloat(item.service.price) * item.quantity, 0);
    const tipValue = servicesTotal * (percentage / 100);
    setTipWasSelected(true)
    setTip(tipValue.toFixed(2));
    calculateTotal(services, tipValue, discount);
  };

  const handleCustomTipChange = (e) => {
    const tipValue = e.target.value;
    setTipWasSelected(true)
    setTip(tipValue);
    calculateTotal(services, tipValue, discount);
  };

  const handleDiscountChange = (e) => {
    const discountValue = formatCurrency(e.target.value);
    setDiscount(discountValue);
    setCustomDiscount(discountValue);
    calculateTotal(services, tip, discountValue);
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const calculateTotal = (services, tipAmount, discountAmount) => {
    const servicesTotal = services.reduce((sum, item) => sum + (parseFloat(item.service.price) * item.quantity), 0);
    const tipValue = parseFloat(tipAmount);
    const discountValue = parseFloat(discountAmount);
    const total = servicesTotal + (isNaN(tipValue) ? 0 : tipValue) - (isNaN(discountValue) ? 0 : discountValue);
    const newDue = total - Number(paidAmount);
    setTotalAmount(total);
    setDueAmount(newDue);
    return total;
  };

  const TipSelectionBox = () => {
    const [inputTipValue, setInputTipValue] = useState(tip);
    const handleFocus = (event) => event.target.select();
    // enter listener
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        handleCustomTipBlur();
        e.target.blur();
      }
    };

    const handleCustomTipChange = (e) => {
      setInputTipValue(e.target.value); // Update local variable with current input
    };
    const handleCustomTipBlur = () => {
      const tipValueCents = parseFloat(inputTipValue); // already in cents
      // setCustomTipAmount(tipValueCents); // Update the state when input loses focus
      // const tipValue = e.target.value;
      setTipWasSelected(true)
      setTip(tipValueCents);
      calculateTotal(services, tipValueCents, discount);
    };
    return (
      <table>
        <thead>
          <tr>
            <th colSpan="6">Tip Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2"><button className="tip-button" onClick={() => applyTip(20)}>20%</button></td>
            <td colSpan="2"><button className="tip-button" onClick={() => applyTip(25)}>25%</button></td>
            <td colSpan="2"><button className="tip-button" onClick={() => applyTip(30)}>30%</button></td>
          </tr>
          <tr>
            <td colSpan="3">
            <button className="tip-button" onClick={() => { setTipWasSelected(true); setTip('0.00'); }}>No Tip</button>
            </td>
            <td colSpan="3">
              {customTip
                ? (
                  <>Tip Amount: $<input
        type="text"
        value={inputTipValue}
        onChange={handleCustomTipChange}
        onBlur={handleCustomTipBlur}
        onKeyDown={handleKeyDown}
        className='tip-input'
        onFocus={handleFocus}
      /></>
                  )
                : (
                  <button className="tip-button" onClick={() => setCustomTip(true)}>Custom</button>
                  )}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  useEffect(() => {
    calculateTotal(services, tip, discount);
  }, [services, tip, discount]);

  const removeService = (servicePerformedUuid) => {
    return fetch('/api/admin/removeService', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        servicePerformedUuid,
      })
    })
      .then(response => response.json())
      .then(data => {
        const updatedServices = services.filter(service => service.uuid !== servicePerformedUuid);
        // Update parent state
        handleFieldChange('servicePerformeds', updatedServices);
      });
  };
  return (
    <div className='services-table'>
    <table>
      <thead>
        <tr>
          <th>Service</th>
          <th>Amount</th>
          <th>Quantity</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {services.map((servicePerformed) => (
          <tr key={servicePerformed.uuid}>
            <td>{servicePerformed.service.name}</td>
            <td>${formatPrice(servicePerformed.service.price)}</td>
            <td>{servicePerformed.quantity}</td>
            <td><button onClick={() => removeService(servicePerformed.uuid)}>Remove</button></td>
          </tr>
        ))}
        <tr>
            <td colSpan="3" className='total-label'>Tip</td>
            <td className='total-value'>
              <div>${formatPrice(tip)}</div>
            </td>
          </tr>
        <tr>
            <td colSpan="3" className='total-label'>Discount</td>
            <td className='total-value'>
            $<input
              type="text"
              value={customDiscount}
              onChange={handleDiscountChange}
              onFocus={handleFocus}
              className='discount-input'
              style={{ maxWidth: '60px' }}
            />
            </td>
          </tr>
          <tr>
            <td colSpan="3" className='total-label'>Total</td>
            <td className='total-value'>${formatPrice(calculateTotal(services, tip, discount))}</td>
          </tr>
          <tr>
            <td colSpan="3" className='total-label'>Paid</td>
            <td className='total-value'>${paidAmount.toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan="3" className='total-label'>Due</td>
            <td className='total-value'>${dueAmount.toFixed(2)}</td>
          </tr>
      </tbody>
    </table>
    {dueAmount > 0.49 && <TipSelectionBox />}
    <br/>

    {/* <button className="tip-button" onClick={() => applyTip(10)}>10%</button>{' '}
              <button className="tip-button" onClick={() => applyTip(15)}>15%</button>{' '}
              <button className="tip-button" onClick={() => applyTip(20)}>20%</button>{' '}<br/>
              {customTip
                ? (
                  <><input
                  type="text"
                  value={tip}
                  onChange={handleCustomTipChange}
                  className='tip-input'
                /><br/></>
                  )
                : (
                <button className="tip-button" onClick={() => setCustomTip(true)}>Custom</button>
                  )}
                  {' '}<button className="tip-button" onClick={() => setTip('0.00')}>No Tip</button> */}
  </div>
  );
};

export default ServicesTable;
