import React from 'react';

const LogoutButton = () => {
  const initiateLogout = () => {
    localStorage.removeItem('acl');
    fetch("/api/auth/logout", {
      credentials: 'include' // necessary to include cookies
  }).then(data => {
      if (data.authenticated) {
          setIsAuthenticated(true);
      } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
      }
  });
  };

  return (
    <button onClick={initiateLogout}>
      Logout
    </button>
  );
}

export default LogoutButton;
