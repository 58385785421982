import React from 'react';
import './intro.css';
import introImage from '../../../../images/altweightloss_good.webp';

const IntroSection = ({ pageContext }) => (
  <div className="intro-section">
  <br/>
    <div className="intro-content">
      <h1>Welcome to Luxe Mobile IV</h1>
      <p className='intro-desc'>Are you an employee or a patient?</p>
      {/* <p className="intro-details">
  {pageContext.ctaBenefits.split(' | ').map((benefit, index) => (
    <span key={index}>{benefit}<br/></span>
  ))}
      </p> */}
      <div className="intro-buttons">
        <a href="/renew"><button type="button" className='bookOnlineButton'>Patient</button></a>
        <a href="/login"><button type="button" className='bookOnlineButton'>Employee</button></a>
      </div>
    </div>
    {/* <div className="intro-image"> */}
      {/* <img src={introImage} alt="Luxe Mobile IV" /> */}
    {/* </div> */}
  </div>
);

export default IntroSection;
