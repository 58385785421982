import React, { useEffect, useState, useRef } from 'react';
import './navbar.css';

const containerStyle = {
  maxWidth: '500px',
  maxHeight: '350px',
  height: 'calc(75vw - 1rem)',
  margin: 'auto',
  position: 'relative',
};

function NavBar ({ pageContext }) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [nearest, setNearest] = useState(<></>);
  const [modal, setModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [urlParams, setUrlParams] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('+18329797034');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(window.location.search);
      setUrlParams(searchParams.toString());

      const cityID = localStorage.getItem('cityID');
      const url = window.location.href.toLowerCase();

      switch (true) {
        case cityID === '4' || url.includes('rgv'):
          setPhoneNumber('+19564318240');
          break;
        case cityID === '2' || url.includes('austin'):
          setPhoneNumber('+17373258075');
          break;
        default:
          setPhoneNumber('+18329797034');
      }
    }
  }, []);

  const getUrlWithParams = (url) => {
    return `${url}${urlParams ? `?${urlParams}` : ''}`;
  };
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  function toggleVisible () {
    setIsNavExpanded(!isNavExpanded); // This toggles the state directly
  }
  const handleBookOnlineClick = (e) => {
    e.preventDefault();
    const allServicesElement = document.getElementById('allservices');
    if (allServicesElement) {
      allServicesElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // This function handles the dropdown visibility
  function toggleDropdown (e) {
    e.preventDefault();
    const dropdownContent = e.currentTarget.nextSibling;
    const isVisible = dropdownContent.style.display === 'block';
    dropdownContent.style.display = isVisible ? 'none' : 'block';
  }

  // const getUrlWithParams = (url) => {
  //   if (typeof window !== 'undefined') {
  //     const currentUrl = new URL(window.location.href);
  //     currentUrl.pathname = url;
  //     return decodeURIComponent(currentUrl.toString());
  //   }
  //   return url;
  // };
  return (
    <nav className="navbar">
      <a href={'https://www.luxemobileiv.com'} className="navbar-logo">
        <img alt="logo" src={require('../../../images/luxe_logo.webp')} className="navbar-logo-img" />
      </a>
      <div className="navbar-links-container">
        <div className="navbar-text-links">
          <a href={getUrlWithParams('https://www.luxemobileiv.com/weightloss')}>Weight Loss Injections</a>
          <div style={{ borderLeft: '1.5px solid #0F9E82', height: '50px' }}></div>
          <div className="dropdown">
            <a href={getUrlWithParams('https://www.luxemobileiv.com/more')} onClick={toggleDropdown}>
              More
            </a>
            <div className="dropdown-content">
              <a href={getUrlWithParams('https://www.luxemobileiv.com/about')}>About</a>
              <a href={getUrlWithParams('https://www.luxemobileiv.com/hangover')}>Hangover Drips</a>
              <a href={getUrlWithParams('https://www.luxemobileiv.com/advanced-medicine')}>Advanced Medicine Drips</a>
              <a href={getUrlWithParams('https://www.luxemobileiv.com/hydration')}>Hydration Drips</a>
              <a href={getUrlWithParams('https://www.luxemobileiv.com/workplacehydration')}>Workplace Hydration Drips</a>
              <a href={getUrlWithParams('https://www.luxemobileiv.com/sickness')}>Sickness Drips</a>
            </div>
          </div>
        </div>
        <div className="navbar-buttons">
        <a href={getUrlWithParams('https://www.luxemobileiv.com/#allservices')}>
          <button className="navbar-button">BOOK ONLINE</button>
        </a>
        {pageContext && pageContext.phoneNumber
          ? <a href={`tel:${pageContext.phoneNumber}`}><button className="navbar-button">
              CALL
            </button>
          </a>
          : <a href={`tel:${phoneNumber}`}><button className="navbar-button">
              CALL
            </button>
          </a> }

        {/* <a href={`tel:${phoneNumber}`}>
            <button onClick={toggleModal} className="navbar-button">
              CALL NOW
            </button>
          </a> */}
        </div>
      </div>
      <div className="navigationToggle" onClick={toggleVisible}>
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </div>
      <ul className={isNavExpanded ? 'navbar-menu expanded' : 'navbar-menu closed'}>
        <a href={getUrlWithParams('/about')}>About</a>
        <a href={getUrlWithParams('/weightloss')}>Weight Loss Injections</a>
        <a href={getUrlWithParams('/hangover')}>Hangover Drips</a>
        <a href={getUrlWithParams('/advanced-medicine')}>Advanced Medicine Drips</a>
        <a href={getUrlWithParams('/hydration')}>Hydration Drips</a>
        <a href={getUrlWithParams('/workplacehydration')}>Workplace Hydration Drips</a>
        <a href={getUrlWithParams('/sickness')}>Sickness Drips</a>
      </ul>
    </nav>
  );
}

export default NavBar;
