import React from 'react';
import './intro.css';
import introImage from '../../../../images/altweightloss_good.webp';

const IntroSection = ({ pageContext }) => (
  <div className="intro-section">
    <div className="intro-content">
      <h1>{pageContext.introHead}</h1>
      <p className='intro-desc'>{pageContext.introDesc}</p>
      {/* <p className="intro-details">
  {pageContext.ctaBenefits.split(' | ').map((benefit, index) => (
    <span key={index}>{benefit}<br/></span>
  ))}
      </p> */}
      <div className="intro-buttons">
        <a href="/GlpCheckoutPage"><button type="button" className='bookOnlineButton'>RENEW HERE</button></a>
        <a href="https://www.luxemobileiv.com/weightloss/"><button type="button" className='bookOnlineButton'>TYPES OF WEIGHTLOSS</button></a>
      </div>
    </div>
    <div className="intro-image">
      <img src={introImage} alt="Luxe Mobile IV" />
    </div>
  </div>
);

export default IntroSection;
