import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './scheduleStyles.css'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const localizer = momentLocalizer(moment);

const AdminCalendar = ({ userDetails }) => {
  const [schedule, setSchedule] = useState([]);
  const { cityId } = useParams()
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [scheduleData, setScheduleData] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());

  
  const handleDateChange = (date) => {
    setCurrentDate(date);
  };

  useEffect(() => {
    // Check user's authentication status when component mounts
    // This could be a call to your server or checking local storage/session storage for a valid token
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      });
    fetchSchedule(currentDate);
  }, []);

  useEffect(() => {
    fetchSchedule(currentDate);
  }, [currentDate]);

  const fetchSchedule = async (date) => {
    try {
      const response = await fetch('/api/admin/getWorkSchedule', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          date,
          city: cityId, // replace with real city reference
        }),
      });
      const data = await response.json();

      // console.log('data', data)
      if (response.ok) {
        setScheduleData(data)
        setSchedule(processScheduleData(data));
      } else {
        throw new Error('Network response was not ok.');
      }
    } catch (error) {
      console.error('Error fetching work schedule:', error);
    }
  };

  const isWithinWorkHours = (timeSlot, startTime, endTime) => {
    return timeSlot.isBetween(moment(startTime), moment(endTime), null, '[]');
  };

  const processScheduleData = (data) => {
    // console.log(data, currentDate)
    const staffSchedules = data.reduce((acc, item) => {
      const staffName = `${item.staff.firstName} ${item.staff.lastName}`;
      if (!acc[staffName]) acc[staffName] = [];

      item.staff.calendarBlocks.forEach(block => {
        // console.log(item.staff.extraStuff?.color)
        // console.log('item from calendarBlocks', block)
        acc[staffName].push({
          startTime: moment(block.startTime),
          endTime: moment(block.endTime),
          bookingId: (block.booking ? block.booking.uuid : null),
          title: (block.booking ? `${block.source}: ${block.booking.patient.firstName}` : block.source),
          color: item.staff.extraStuff?.color,
          address: (block.booking ? block.booking.address1 : null),
          city: (block.booking ? block.booking.city : null)
        });
      });

      return acc;
    }, {});

    return staffSchedules;
  };

  const handleCellClick = (bookingId) => {
    navigate(`/admin/bookingNew/${bookingId}`);
  };

  const hexToRGBA = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const getBookingDetails = (timeSlot, staffSchedule) => {
    const booking = staffSchedule.find(block =>
      timeSlot.isBetween(block.startTime, block.endTime, null, '[]')
    );
    return booking || {};
  };

  const getRelevantBookingsForSlot = (timeSlot, staffSchedule) => {
    // console.log('staffSchedule', staffSchedule)
    // console.log('timeSlot', timeSlot)
    return staffSchedule.filter(block => {
      return (timeSlot.isSameOrAfter(block.startTime, 'minute') &&
      timeSlot.isBefore(block.endTime, 'minute'))
    }
    );
  };

  const navigateDay = (direction) => {
    setCurrentDate(prevDate => {
      const newDate = moment(prevDate);
      if (direction === 'forward') {
        newDate.add(1, 'days');
      } else if (direction === 'backward') {
        newDate.subtract(1, 'days');
      }
      fetchSchedule(newDate.toDate()); // Fetch new schedule for the updated date
      return newDate.toDate();
    });
  };

  const createTimeSlots = (currentDate) => {
    const slots = [];
    const startOfDay = moment(currentDate).startOf('day');
    const time = startOfDay.clone().add(6, 'hours').add(15, 'minutes'); // Start at 6:30 AM
    // stop at 11 pm
    while (time.format('hh:mm A') !== '11:00 PM') {
      slots.push(time.clone());
      time.add(15, 'minutes');
    }

    return slots;
  };
  const timeSlots = createTimeSlots(currentDate);

  const isTimeSlotBooked = (timeSlot, staffSchedule) => {
    return staffSchedule.some(block =>
      timeSlot.isBetween(block.startTime, block.endTime, null, '[]')
    );
  };

  return (
    <div className="admin-calendar-container">
      <div className='adminPageTitle'>
        <h1>Calendar block view</h1>
        <p>Note calendar blocks include appointment time and drive time</p>
      </div>
      <div className="calendar-navigation">
      <button onClick={() => navigateDay('backward')} className="navigate-button">-</button>
      <DatePicker
        selected={currentDate}
        onChange={handleDateChange}
        dateFormat="MMMM d, yyyy"
        className="date-picker"
      />
      <button onClick={() => navigateDay('forward')} className="navigate-button">+</button>
    </div>

      <table className="scheduleTable">
        <thead>
          <tr>
            <th style={{ minWidth: '75px' }}>Time</th>
            {Object.keys(schedule).map((staffName, index) => (
              <th key={index}>{staffName}</th>
            ))}
          </tr>
        </thead>
        <tbody id="scheduleTable">
        {timeSlots.map((slot, index) => (
  <tr key={index}>
    <td>{slot.format('hh:mm A')}</td>
    {Object.entries(schedule).map(([staffName, staffSchedule], idx) => {
      const staffWorkHours = scheduleData.find(item => `${item.staff.firstName} ${item.staff.lastName}` === staffName);
      const isSlotWithinWorkHours = staffWorkHours && isWithinWorkHours(slot, staffWorkHours.startTime, staffWorkHours.endTime);

      const relevantBookings = getRelevantBookingsForSlot(slot, staffSchedule);
      if (relevantBookings.length > 0) {
        return (
          <td
            key={idx}
            style={{ backgroundColor: isSlotWithinWorkHours ? relevantBookings[0].color : 'grey', borderColor: relevantBookings[0].color }}
          >
    {relevantBookings.map((booking, bIdx) => (
  <div key={bIdx}
  >
    {slot.isSame(booking.startTime, 'minute') && (
      <a href={`/admin/bookingNew/${relevantBookings[bIdx].bookingId}`}>
        <div>{booking.title}</div>
        <div>{booking.address}</div>
        <div>{booking.city}</div>
      </a>
    )}
  </div>
    ))}
          </td>
        );
      } else {
        return <td key={idx} style={{ backgroundColor: isSlotWithinWorkHours ? 'transparent' : 'grey' }}></td>;
      }
    })}
  </tr>
        ))}
</tbody>
      </table>

      <div className="calendar-bottom-space">
        <button onClick={() => navigate('/admin/byCity/' + cityId)} className="navigate-button">
          Back
        </button>
      </div>
    </div>
  );
};

export default AdminCalendar;
