import React from 'react';
import { OverlayView } from '@react-google-maps/api';

const CustomMarker = ({ position, text, color, uuid }) => {
  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height + 10), // Adjusts the position of the label
  });

  const formattedText = text.split('<br/>').map((line, index) => (
    <div key={index}>{line}</div>
  ));

  return (
    <OverlayView
      position={position}
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      getPixelPositionOffset={getPixelPositionOffset}
    >
      <a href={`/admin/bookingNew/${uuid}`}>
        <div style={{ position: 'absolute' }}>
          <div style={{
            color: 'white', // Text color
            background: color,
            padding: '2px 5px',
            borderRadius: '3px',
            fontSize: '12px'
          }}>
            {formattedText}
          </div>
          <div style={{
            border: '10px solid transparent',
            borderTopColor: color,
            position: 'absolute',
            top: '100%',
            left: '50%',
            marginLeft: '-10px',
            marginTop: '-1px'
          }} />
        </div>
      </a>
    </OverlayView>
  );
};

export default CustomMarker;
