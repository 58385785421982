import React, { lazy, Suspense } from 'react';
import { Route, Routes, Router } from 'react-router-dom';
// import TreeRenderer from '../components/Tree/TreeRenderer.js';
import AdminHome from '../components/admin/AdminHome'
import Home from '../components/admin/Home'
import Login from '../components/admin/Login'
import AdminHomeByCity from '../components/admin/AdminHomeByCity';
// import BookingDetails from '../components/admin/BookingDetails'; // Ensure this is imported
import BookingDetailsNew from '../components/admin/BookingDetailContainer'; // Ensure this is imported
import BookingTreeSessions from '../components/admin/tree/TreeViewerSingleBooking';
import TableViewOfManyBookings from '../components/admin/TableViewOfManyBookings';
import TableViewOfPaidBookingsForNurse from '../components/admin/TableViewOfPaidBookingsForNurse';
import ViewPayments from '../components/admin/payments/ViewPayments';
import InventoryHome from '../components/admin/InventoryHome';
import Inventory from '../components/admin/inventory/Inventory';
import Supply from '../components/admin/inventory/Supply';
import SelectBookingsForCheckout from '../components/admin/payments/SelectBookingsForCheckout';
import CheckoutSingle from '../components/admin/payments/CheckoutSingle2';
import CheckoutMultiple from '../components/admin/payments/CheckoutMultiple2';
import CheckoutSingleBraintree from '../components/admin/payments/CheckoutSingleBraintree';
import CheckoutMultipleBraintree from '../components/admin/payments/CheckoutMultipleBraintree';
import CheckoutSingleHelcim from '../components/admin/payments/CheckoutSingleHelcim';
import CheckoutMultipleHelcim from '../components/admin/payments/CheckoutMultipleHelcim';
import DoctorReview from '../components/admin/DoctorReview';
import SearchBookings from '../components/admin/SearchBookings';
import AdminWorkSchedule from '../components/admin/AdminWorkSchedule';
import ScheduleAvailability from '../components/admin/ScheduleAvailability';
import MonochromeStaffAvailabilityCalendar from '../components/admin/MonochromeStaffAvailabilityCalendar';
import CustomerServiceIssueBookings from '../components/admin/CustomerServiceIssueBookings';
import CreateABooking from '../components/admin/CreateABooking';
import SceduleMap from '../components/admin/ScheduleMap';
import GlpCheckoutPage from '../components/admin/glpCheckout/GlpCheckoutPage';
import GlpLandingPage from '../components/admin/glpCheckout/GlpLandingPage';
import ExitSurveyPage from '../components/admin/exitSurvey/ExitSurveyPage';
import TreeViewer from '../components/admin/tree/TreeViewer';
import Thankyou from '../components/admin/exitSurvey/Thankyou';
import Visitcomplete from '../components/admin/exitSurvey/Visitcomplete';
import MonthlySchedule from '../components/admin/MonthlySchedule';
import ViewSchedule from '../components/admin/ViewSchedule';
import PatientLookup from '../components/admin/PatientLookup';
import DynamicScheduleView from '../components/admin/DynamicScheduleView';

const renderLoader = () => <p>Loading</p>;

export default (
  <>
    <Route
      path="/"
      element={ <Home/>}
    />
    <Route
      path="/admin"
      element={ <AdminHome/>}
    />
    <Route
      path="/login"
      element={ <Login/>}
    />
    <Route
      path="/admin/bycity/:cityId"
      element={ <AdminHomeByCity/>}
    />
    <Route
      path="/admin/incomplete-bookings"
      element={ <TableViewOfManyBookings/>}
    />
    <Route
      path="/admin/bookingTableView/:status/:cityId"
      element={<TableViewOfManyBookings/>}
    />
    <Route
      path="/admin/paidBookingsNurseView/"
      element={<TableViewOfPaidBookingsForNurse/>}
    />
    <Route
      path="/admin/bookingNew/:bookingUUID"
      element={<BookingDetailsNew />}
    />
    <Route
      path="/admin/bookingTreeSessions/:bookingUUID"
      element={<BookingTreeSessions />}
    />
    <Route
      path="/admin/payments"
      element={<BookingDetailsNew />}
    />
    <Route
      path="/admin/monthlySchedule/:cityId"
      element={<MonthlySchedule />}
    />
    <Route
      path="/admin/searchBookings/:cityId"
      element={<SearchBookings />}
    />
    <Route
      path="/admin/doctor-review"
      element={<DoctorReview />}
    />
        <Route
      path="/admin/workSchedule/:cityId"
      element={ <AdminWorkSchedule/>}
    />
        <Route
      path="/admin/ViewSchedule/:cityId"
      element={ <ViewSchedule/>}
    />
        <Route
      path="/admin/scheduleAvailability/:cityId"
      element={ <ScheduleAvailability/>}
    />
        <Route
      path="/admin/MonochromeStaffAvailabilityCalendar/:cityId"
      element={ <MonochromeStaffAvailabilityCalendar/>}
    />
        <Route
      path="/admin/customerServiceBookings"
      element={ <CustomerServiceIssueBookings/>}
    />
        <Route
      path="/admin/lookupPatient"
      element={ <PatientLookup/>}
    />
        <Route
      path="/admin/dynamicScheduleView/:cityId"
      element={ <DynamicScheduleView/>}
    />
        <Route
      path="/admin/inventory"
      element={ <Inventory/>}
    />
        <Route
      path="/admin/inventoryHome"
      element={ <InventoryHome/>}
    />
        <Route
      path="/admin/supply"
      element={ <Supply/>}
    />
        <Route
      path="/admin/createBooking/:cityId"
      element={ <CreateABooking/>}
    />
        <Route
      path="/admin/supply"
      element={ <Supply/>}
    />
        <Route
      path="/admin/selectBookingsForCheckout/:status/:cityId"
      element={ <SelectBookingsForCheckout/>}
    />
        <Route
      path="/checkout/:bookingUUID"
      element={ <CheckoutSingle/>}
    />
        <Route
      path="/checkoutMultiple/:multipleBookingUUID/:groupPhoneNumber"
      element={ <CheckoutMultiple/>}
    />
        <Route
      path="/receipt/:bookingUUID"
      element={ <CheckoutSingle/>}
    />
        <Route
      path="/receiptMultiple/:multipleBookingUUID/:groupPhoneNumber"
      element={ <CheckoutMultiple/>}
    />
        <Route
      path="/admin/schedulemap/:cityId"
      element={ <SceduleMap/>}
    />
        <Route
      path="/admin/treeViewer"
      element={ <TreeViewer/>}
    />
        <Route
      path="/exitSurvey/:bookingUUID"
      element={ <ExitSurveyPage/>}
    />
        <Route
      path="/GlpCheckoutPage"
      element={ <GlpCheckoutPage/>}
    />
        <Route
      path="/renew"
      element={ <GlpLandingPage/>}
    />
        <Route
      path="/Visitcomplete"
      element={ <Visitcomplete/>}
    />
        <Route
      path="/Thankyou/:cityId"
      element={ <Thankyou/>}
    />
        <Route
      path="/Thankyou/"
      element={ <Thankyou/>}
    />
  </>
);
