import React from 'react';

const AppleMapsLink = ({ bookingData }) => {
  // booking zip code is defined and of proper length
  if (bookingData.zipcode) {
    // Format the address parts
    const addressString = `${bookingData.address1}, ${bookingData.city}, ${bookingData.state} ${bookingData.zipcode}`;

    // Create the Apple Maps URL with the formatted address
    const appleMapsUrl = `https://maps.apple.com/?address=${encodeURIComponent(addressString)}`;

    return (
      <a href={appleMapsUrl} target="_blank" rel="noopener noreferrer">
        View on Apple Maps
      </a>
    );
  } else {
    // Format the address parts
    const addressString = `${bookingData.address1}, ${bookingData.city}, ${bookingData.state}`;

    // Create the Apple Maps URL with the formatted address
    const appleMapsUrl = `https://maps.apple.com/?address=${encodeURIComponent(addressString)}`;

    return (
      <a href={appleMapsUrl} target="_blank" rel="noopener noreferrer">
        View on Apple Maps
      </a>
    );
  }
};

export default AppleMapsLink;
