import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Supply = () => {
  const navigate = useNavigate();
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState('');
  const [selectedServiceInfo, setSelectedServiceInfo] = useState([]);
  const [selectedServicePrice, setSelectedServicePrice] = useState(0);
  const [selectedServiceSupplies, setSelectedServiceSupplies] = useState([]);
  const [supplies, setSupplies] = useState([]);

  useEffect(() => {
    // Check user's authentication status when component mounts
    // This could be a call to your server or checking local storage/session storage for a valid token
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated && data.user.acl > 5) {
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      });
  }, []);


  // function to get services
  const getServices = () => {
    fetch('/api/admin/getServices', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(data => {
        // console.log('services', data);
        setServices(data);
        if (data.length > 0) {
          setSelectedService(data[0].uuid);
          setSelectedServicePrice(data[0].price || 0);
          setSelectedServiceSupplies(data[0].supplyToServices || []);
        }
      })
      .catch(error => console.error('Error fetching services:', error));
  }

  useEffect(() => {
    getServices();
  }, []);

  useEffect(() => {
    fetch('/api/admin/getSupplies', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => response.json())
      .then(data => {
        setSupplies(data);
      })
      .catch(error => console.error('Error fetching supplies:', error));
  },[]);

  const handleServiceChange = (e) => {
    const selected = services.find(service => service.uuid === e.target.value);
    setSelectedService(selected.uuid);
    setSelectedServiceInfo(selected);
    setSelectedServicePrice(selected.price || 0);
    setSelectedServiceSupplies(selected.supplyToServices || []);
  };

  const handleServicePriceChange = (e) => {
    setSelectedServicePrice(e.target.value);
  };

  useEffect(() => {
    if (selectedService) {
      fetch('/api/admin/getSuppliesExpress', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ serviceId: selectedService }) // Assuming the API needs the selected service ID
      })
        .then(response => response.json())
        .then(data => {
          // console.log(data)
          setSupplies(data);
        })
        .catch(error => console.error('Error fetching supplies:', error));
    }
  }, [selectedService]);

  const updateService = () => {
    fetch('/api/admin/updateServiceDetails', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        serviceId: selectedService,
        price: selectedServicePrice
      })
    })
      .then(response => response.json())
      .then(data => console.log('Service updated:', data))
      .catch(error => console.error('Error updating service:', error));
  };


  const removeSupplyFromService = (supplyUuid) => {
    fetch('/api/admin/removeSupplyFromService', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        supplyUuid,
        serviceUuid: selectedService
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log('Supply removed:', data);
        setSelectedServiceSupplies(prevSupplies => prevSupplies.filter(supply => supply.supplyUuid !== supplyUuid));
      })
      .catch(error => console.error('Error removing supply:', error));
  };
  
  // attach supply to service
  const attachSupplyToService = () => {
    const supplyUuid = document.getElementById('supplySelect').value;
    const quantity = parseFloat(document.getElementById('supplyQuantity').value, 10);
    const body = JSON.stringify({
      supplyUuid,
      serviceUuid: selectedService,
      quantity
    });
    console.log('body for attachSupplyToService', body);
    fetch('/api/admin/attachSupplyToService', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    })
      .then(response => response.json())
      .then(data => {
        console.log('Supply attached:', data);
        const prevSupplies = selectedServiceSupplies;
        setSelectedServiceSupplies([...prevSupplies, data]);
      })
      .catch(error => console.error('Error attaching supply:', error));
  };

  return (
    <div className='suppliesPage' style={{ textAlign: 'left', padding: '20px', fontFamily: 'Arial, sans-serif' }}>
    <h1>Define Supplies in Each Drip</h1>
    <div style={{ marginBottom: '20px' }}>
      <label htmlFor="serviceSelect">Service:</label>
      <select id="serviceSelect" value={selectedService} onChange={handleServiceChange} style={{ padding: '5px', fontSize: '16px', marginLeft: '10px' }}>
        {services.map(service => (
          <option key={service.uuid} value={service.uuid}>{service.name}</option>
        ))}
      </select>
    </div>

    <div style={{ marginBottom: '20px' }}>
      Type: {selectedServiceInfo.AddOn ? 'Add On' : 'IV'} <br/>
      {console.log('selectedServiceInfo', selectedServiceInfo)}
      {console.log('selectedServiceAddOn', selectedServiceInfo.AddOn)}
      <label htmlFor="servicePrice">Price:</label>
      <input
        type="number"
        id="servicePrice"
        value={selectedServicePrice}
        onChange={handleServicePriceChange}
        style={{ padding: '5px', fontSize: '16px', marginLeft: '10px', width: '100px' }}
      />
      <button onClick={updateService} style={{ padding: '5px 10px', fontSize: '16px', marginLeft: '10px' }}>Update Price</button> <span className="todo"> Please don't update prices use yet</span> <br/>
      Nurse Payment: {selectedServiceInfo.nursePayment ? selectedServiceInfo.nursePayment : '$0' } <br/>
    </div>

    <div style={{ marginBottom: '20px' }}>
      <label htmlFor="supplySelect">Supply:</label>
      <select id="supplySelect" style={{ padding: '5px', fontSize: '16px', marginLeft: '10px' }}>
        {supplies.map(supply => (
          <option key={supply.uuid} value={supply.uuid}>{supply.name}</option>
        ))}
      </select>
      <label htmlFor="supplyQuantity" style={{ marginLeft: '10px' }}>Quantity:</label>
      <input
        type="number"
        id="supplyQuantity"
        min="1"
        defaultValue="1"
        style={{ padding: '5px', fontSize: '16px', marginLeft: '5px', width: '60px' }}
      />
      <button onClick={attachSupplyToService} style={{ padding: '5px 10px', fontSize: '16px', marginLeft: '10px' }}>
        Attach Supply
      </button>
    </div>

      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Supply</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Unit of <br/>measurement</th>
            {/* <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Unit of measurement</th> */}
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Units</th>
            <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}> remove </th>
          </tr>
        </thead>
        <tbody>
        {selectedServiceSupplies.map(supply => (
            <tr key={supply.supplyUuid}>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{supply.supply.name}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{supply.supply.unitOfMeasure}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>{supply.quantity}</td>
              <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                <button onClick={() => removeSupplyFromService(supply.supplyUuid)}> 🗑️  </button>
              </td>
            </tr>
        ))}
        </tbody>
      </table>

      <button onClick={() => navigate('/admin/inventoryHome')}>Back</button><br />
      <p className='todo'>
      </p>
    </div>
  );
}

export default Supply;
