import React, { useState, useEffect } from 'react';
import TreeRenderer from '../tree/TreeRenderer'
import Modal from 'react-modal';
import TreeViewSummary from '../tree/TreeViewSummary';


const MedicalTabContent = ({ bookingData, handleFieldChange, setChangeMade }) => {
  const initialNurseNotes = bookingData.nurseNotes ? JSON.parse(bookingData.nurseNotes) : [];
  const initialData = bookingData.patientMedicalNotes
    ? bookingData.patientMedicalNotes
    : {
        PMHx: '',
        Meds: '',
        Allergies: '',
        BloodPressure: '',
        HeartRate: '',
        PulseOx: '',
        IVAttempts: '',
        IVPlacement: '',
        IVGauge: '18g',
        FluidType: 'NS',
        StartTime: '',
        StopTime: '',
        FluidVolume: '',
        NurseNotes: initialNurseNotes
      };
  const [user, setUser] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [currentTree, setCurrentTree] = useState(null);
  const [medicalData, setMedicalData] = useState(initialData);
  const [newNurseNote, setNewNurseNote] = useState(''); // State for new nurse note
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  useEffect(() => {
    // console.log('bookingData in useEffect in medicalTabContent:', bookingData)
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setUser(data.user)
          if (!bookingData?.extraStuff?.nurseArrivalTime) {
            setWarningModalOpen(true)
          } 
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  const handleNurseNoteChange = (event) => {
    setNewNurseNote(event.target.value);
    setChangeMade(true)
  };

  const openForm = (treeName) => {
    setCurrentTree(treeName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // setCurrentTree(null);
  };

  const closeWarningModal = () => {
    setWarningModalOpen(false);
    // setCurrentTree(null);
  };

  const handleChange = (event) => {
    // ensure the nurse has marked the booking as arrived before allowing changes
    if (!bookingData?.extraStuff?.nurseArrivalTime) {
      setWarningModalOpen(true);
      return;
    } else {
      setChangeMade(true)
      if (event.target.name === 'NurseNotes') {
        // Handle changes specifically for NurseNotes
        const newNote = { date: new Date().toISOString().split('T')[0], note: event.target.value };
        setMedicalData({
          ...medicalData,
          NurseNotes: [...medicalData.NurseNotes, newNote]
        });
      } else {
        // Handle changes for other fields
        setMedicalData({
          ...medicalData,
          [event.target.name]: event.target.value
        });
      }
    }
  };

  // required field check use effect
  useEffect(() => {
    const requiredFields = [
      'PMHx', 'Meds', 'Allergies', 'BloodPressure', 'HeartRate',
      'PulseOx', 'IVAttempts', 'IVPlacement', 'IVGauge', 'FluidType',
      'StartTime', 'StopTime'
    ];

    const areRequiredFieldsFilled = requiredFields.every(field =>
      medicalData[field] && medicalData[field].trim() !== ''
    );

    setIsSaveButtonDisabled(!areRequiredFieldsFilled);
  }, [medicalData]);

  const updateMedInfo = (event) => {
    const button = event.target;
    // Add new nurse note to the list of notes
    const updatedNurseNotes = [
      ...medicalData.NurseNotes,
      { date: new Date().toISOString().split('T')[0], note: newNurseNote }
    ];

    let updatedMedicalData;
    if (newNurseNote.length > 1) {
      // Update medical data with new nurse notes
      updatedMedicalData = {
        ...medicalData,
        NurseNotes: updatedNurseNotes
      };
    } else {
      updatedMedicalData = {
        ...medicalData
      };
    }

    // Call API to save updated medical data
    fetch('/api/admin/updateBooking', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingData.uuid,
        patientMedicalNotes: updatedMedicalData
      })
    })
      .then(response => response.json())
      .then(data => {
        setMedicalData(updatedMedicalData); // Update state with saved data
        handleFieldChange('patientMedicalNotes', updatedMedicalData); // Update state with saved data
        setNewNurseNote(''); // Clear new nurse note input
        setChangeMade(false)
        button.classList.add('green');
        setTimeout(() => button.classList.remove('green'), 3000);
      });
  };

  return (
    <div className='adminParent adminTabSection'>
      <Modal isOpen={warningModalOpen} onRequestClose={closeModal} style={{ content: { width: '100%', height: '100%' } }}>
          <p className='todo'>You need to indicate you've arrived before entering medical information</p>
          <button onClick={closeWarningModal}> Close</button>
      </Modal>
      <button onClick={updateMedInfo} className='saveButton'>Save</button> <br/>
      <div>
        <table>
          <tbody>
            <tr>
              <td><label>List PMHx*:</label></td>
              <td><textarea name="PMHx" required value={medicalData.PMHx} onChange={handleChange}></textarea></td>
            </tr>
            <tr>
              <td><label>List Meds*:</label></td>
              <td><textarea name="Meds" required value={medicalData.Meds} onChange={handleChange}></textarea></td>
            </tr>
            <tr>
              <td><label>Allergies*:</label></td>
              <td><input type="text" name="Allergies" required value={medicalData.Allergies} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Blood Pressure*:</label></td>
              <td><input type="text" name="BloodPressure" required value={medicalData.BloodPressure} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Heart Rate*:</label></td>
              <td><input type="text" name="HeartRate" required value={medicalData.HeartRate} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Pulse Ox*:</label></td>
              <td><input type="text" name="PulseOx" required value={medicalData.PulseOx} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>IV Attempts*:</label></td>
              <td><input type="number" name="IVAttempts" required value={medicalData.IVAttempts} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>IV Placement*:</label></td>
              <td><input type="text" name="IVPlacement" required value={medicalData.IVPlacement} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>IV Gauge*:</label></td>
              <td>
                <select name="IVGauge" required value={medicalData.IVGauge} onChange={handleChange}>
                  <option value="18g">18g</option>
                  <option value="20g">20g</option>
                  <option value="22g">22g</option>
                  <option value="24g">24g</option>
                </select>
              </td>
            </tr>
            <tr>
              <td><label>Fluid Type*:</label></td>
              <td>
                <select name="FluidType" required value={medicalData.FluidType} onChange={handleChange}>
                  <option value="NS">NS</option>
                  <option value="LR">LR</option>
                </select>
              </td>
            </tr>
            <tr>
              <td><label>Fluid Volume:</label></td>
              <td>
                <select name="FluidVolume" required value={medicalData.FluidVolume} onChange={handleChange}>
                  <option value="1000">1000 mL</option>
                  <option value="500">500 mL</option>
                  <option value="1500">1500 mL</option>
                  <option value="2000">2000 mL</option>
                </select>
              </td>
            </tr>
            <tr>
              <td><label>Start Time*:</label></td>
              <td><input type="time" name="StartTime" required value={medicalData.StartTime} onChange={handleChange} /></td>
            </tr>
            <tr>
              <td><label>Stop Time*:</label></td>
              <td><input type="time" name="StopTime" required value={medicalData.StopTime} onChange={handleChange} /></td>
            </tr>
            <tr>
            <td><label>Nurse Notes:</label></td>
            <td>
              {medicalData.NurseNotes.map((note, index) => (
                <div key={index}>
                  <strong>{note.date}:</strong> {note.note}
                </div>
              ))}
              <textarea
                name="NurseNotes"
                value={newNurseNote}
                onChange={handleNurseNoteChange}
                placeholder="Add new note..."
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      {/* <div>
        <button onClick={() => openForm('SemaglutideMedicalChart')}>Semaglutide Medical Chart</button>
      </div> */}

      <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={{ content: { width: '100%', height: '100%' } }}>
        <TreeRenderer treeName={currentTree} closeModal={closeModal} bookingData={bookingData}/>
        <button onClick={closeModal}>Close Form</button>
      </Modal>
      <TreeViewSummary bookingUuid={bookingData.uuid} />
      {/* <p className='todo'>
        * Needs lots more information - see the waiver app for the all of the stuff that they record. || done<br/>
        * nurse notes input field is JSON and needs to be handled. Attach date and note. || done <br/>
        * Check Waiver for the order of the inputs. Doesn't make sense now. || done <br/>
        * needed: start and stop time, fluid type (NS or LR), weight height, || done <br/>
        * Needs to have two different forms: IV or Semaglutide. || done
        * many of the forms need to be mandatory.
        * buttons to fill out forms and waivers with signature.
      </p> */}
    </div>
  );
}

export default MedicalTabContent;
