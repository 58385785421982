import React, { useState, useEffect } from 'react';
import ServicesTable from './ServicesTableSingle.js';
import Navbar from '../generalComponents/Navbar.js';
import { PaymentForm, CreditCard, GooglePay, ApplePay } from 'react-square-web-payments-sdk';
import { useParams } from 'react-router-dom';
import { formatDate, formatPrice } from '../utility/module.js';
import BraintreeDropIn from './BraintreeDropIn.js';
import Footer from '../generalComponents/footer.js';
import '../adminBooking/PaymentTabContent.css';

const CheckoutSingle = () => {
  const { bookingUUID } = useParams();
  const [customTip, setCustomTip] = useState(false);
  const [bookingData, setBookingData] = useState({});
  const [paymentStatus, setPaymentStatus] = useState(''); // only tracks, no UI depends on this variable
  const [totalAmount, setTotalAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [dueAmount, setDueAmount] = useState(0);
  const [tipWasSelected, setTipWasSelected] = useState(false);
  const [helcimCheckoutToken, setHelcimCheckoutToken] = useState('');
  const [isHelcimScriptLoaded, setIsHelcimScriptLoaded] = useState(false);
  const [isBraintreeScriptLoaded, setIsBraintreeScriptLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  // load helcim and braintree script
  useEffect(() => {
    const helcimScript = document.createElement('script');
    const braintreeScript1 = document.createElement('script');
    const braintreeScript2 = document.createElement('script');
    const braintreeScript3 = document.createElement('script');
    const braintreeScript4 = document.createElement('script');
    helcimScript.src = 'https://secure.helcim.app/helcim-pay/services/start.js';
    braintreeScript1.src = 'https://js.braintreegateway.com/web/dropin/1.42.0/js/dropin.min.js';
    braintreeScript2.src = 'http://code.jquery.com/jquery-3.2.1.min.js';
    braintreeScript3.src = 'https://js.braintreegateway.com/web/3.99.0/js/client.min.js';
    braintreeScript4.src = 'https://js.braintreegateway.com/web/3.99.0/js/apple-pay.min.js';
    helcimScript.type = 'text/javascript';
    braintreeScript1.type = 'text/javascript';
    braintreeScript2.type = 'text/javascript';
    braintreeScript3.type = 'text/javascript';
    braintreeScript4.type = 'text/javascript';
    braintreeScript2.crossOrigin = 'anonymous';
    helcimScript.onload = () => setIsHelcimScriptLoaded(true); // Set flag when script is loaded
    braintreeScript2.onload = () => setIsBraintreeScriptLoaded(true); // Set flag when script is loaded
    document.body.appendChild(helcimScript);
    document.body.appendChild(braintreeScript1);
    document.body.appendChild(braintreeScript2);
    document.body.appendChild(braintreeScript3);
    document.body.appendChild(braintreeScript4);

    return () => {
      document.querySelectorAll('script[src="https://secure.helcim.app/helcim-pay/services/start.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="https://js.braintreegateway.com/web/dropin/1.42.0/js/dropin.min.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="https://js.braintreegateway.com/web/3.99.0/js/client.min.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="https://js.braintreegateway.com/web/3.99.0/js/apple-pay.min.js"]').forEach(el => el.remove());
      document.querySelectorAll('script[src="http://code.jquery.com/jquery-3.2.1.min.js"]').forEach(el => el.remove());
    };
  }, []);

  // fetch booking data whenever bookingUUID is available (or changes)
  useEffect(() => {
    fetch('/api/getBookingPaymentInfoPublic', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        uuid: bookingUUID,
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log('data from getBookingPaymentInfoPublic:', data)
        setBookingData(data)
      })
      .catch(error => {
        console.error('Error fetching booking details:', error)
      })
  }, [bookingUUID])

  // calculate total when booking data changes
  useEffect(() => {
    // verify booking data is instantiated and services were performed
    if (bookingData.servicePerformeds) {
      calculateTotal(bookingData.servicePerformeds, bookingData.tip, bookingData.discount)
    }
  }, [bookingData])

  // initialize new helcim checkout whenever total amount due changes or tip is selected (for no tip cases)
  useEffect(() => {
    if ((tipWasSelected || (bookingData.extraStuff && bookingData.extraStuff.serviceType === "weight loss")) && dueAmount > 0) {
      setIsDisabled(true); // Disable the button
      // Set a timeout to delay the fetch call
      const timeoutId = setTimeout(() => {
        fetch('/api/intializeHelcimCheckout/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            amount: dueAmount,
            cityID: bookingData.cityCalendarId
          })
        })
          .then(response => response.json())
          .then(data => {
            console.log('helcim tokens', data[0]);
            setHelcimCheckoutToken(data[0].checkoutToken);
            setIsDisabled(false); // Re-enable the button
          // setHelcimSecretToken(data[0].secretToken);
          })
          .catch(error => {
            console.error('Error fetching booking details:', error);
          });
      }, 1500); // 1500 milliseconds = 1.5 seconds

      // Clear the timeout if the component unmounts or if the dependencies change
      return () => clearTimeout(timeoutId);
    }
  }, [tipWasSelected, dueAmount]);

  // helcim show iframe
  const handlePaymentClick = async () => {
    if (isHelcimScriptLoaded && window.appendHelcimPayIframe) {
      // Replace `helcimCheckoutToken` with actual token value
      window.appendHelcimPayIframe(helcimCheckoutToken, true);
    } else {
      console.error('Payment script not loaded yet.');
    }
  };

  // creates payment request dependency and handles completed payment
  useEffect(() => {
    const handlePaymentMessage = async (event) => {
      console.log(helcimCheckoutToken)
      const helcimPayJsIdentifierKey = 'helcim-pay-js-' + helcimCheckoutToken;

      if (event.data.eventName === helcimPayJsIdentifierKey) {
        if (event.data.eventStatus === 'ABORTED') {
          console.error('Transaction failed!', event.data.eventMessage);
        }

        if (event.data.eventStatus === 'SUCCESS') {
          const helcimResponse = JSON.parse(event.data.eventMessage);
          console.log('Transaction success!', helcimResponse);
          // console.log('Transaction success! data', event.data);
          // console.log('Transaction success! event', event);
          const paymentResponse = await fetch('/api/processPayment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              nonce: helcimResponse.data.data.transactionId,
              totalAmount: Number(dueAmount),
              bookingUuid: bookingData.uuid,
              tip: bookingData.tip,
              note: 'react payment',
              // note: bookingData.servicePerformeds.reduce((total, easp) => {
              //   return (total + easp.service.name + ' ')
              // }, ''),
              bookingData,
              extraStuff: helcimResponse.data.data
            })
          });
          console.log(paymentResponse)
          if (paymentResponse.status === 200) {
            // console.log(paymentResponse)
            setPaymentStatus('Payment successful');
            // const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
            // squareButton.classList.add('green');
            // setTimeout(() => squareButton.classList.remove('green'), 3000);
            // handleFieldChange('patientPayments', updatedServices);
            // handleFieldChange('patientPaid', true);
            window.location.reload();
          } else {
            setPaymentStatus('Payment failed');
            // squareButton.classList.add('red');
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `
      .c-jWYnUm {
        background-color: red !important;
        color: white !important;
      }
    `;
            document.head.appendChild(style);
          }
        }
      }
    };
    // Add event listener
    window.addEventListener('message', handlePaymentMessage);

    // Cleanup function
    return () => {
      window.removeEventListener('message', handlePaymentMessage);
    };
  }, [helcimCheckoutToken]);

  // calculates paid total amount
  useEffect(() => {
    const newTotalPaid = bookingData.patientPayments && bookingData.patientPayments.reduce((sum, service) => {
      return sum + parseFloat(service.amount);
    }, 0)
    const newDueAmount = totalAmount - newTotalPaid;
    console.log(newTotalPaid, totalAmount)
    setPaidAmount(newTotalPaid)
    setDueAmount(newDueAmount)
  }, [bookingData.patientPayments, bookingData.servicePerformeds, totalAmount, bookingData, paymentStatus]);

  // calculate total function
  const calculateTotal = (services, tipAmountDollars, discountAmountDollars) => {
    const servicesTotal = services.reduce((sum, item) => sum + parseFloat(item.service.price) * item.quantity, 0);
    const tipValueDollars = parseFloat(tipAmountDollars);
    const discountValueDollars = parseFloat(discountAmountDollars);
    const total = servicesTotal + (!isNaN(tipValueDollars) ? tipValueDollars : 0) - (!isNaN(discountValueDollars) ? discountValueDollars : 0);
    const newDue = total - paidAmount;
    console.log('newDue in calculateTotal in servicesTable:', newDue)
    setTotalAmount(total);
    setDueAmount(newDue);
    return total;
  };

  // creates price dependency for square library component
  const createPaymentRequest = () => {
    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: 'USD',
      countryCode: 'US',
      total: {
        label: 'Total',
        amount: Number(dueAmount),
        pending: false,
      },
      lineItems: bookingData.servicePerformeds.length > 0 && bookingData.servicePerformeds.map(service => ({
        label: service.service.name,
        amount: Number(service.service.price),
        pending: false,
      })),
    };
  };

  const processBraintreeTransaction = async (nonce, setIsBraintreeDisabled) => {
    try {
      setIsBraintreeDisabled(true)
      setIsDisabled(true)
      const response = await fetch('/api/processPayment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          processor: 'braintree',
          nonce,
          totalAmount: dueAmount,
          bookingUuid: bookingData.uuid,
          tip: bookingData.tip,
          note: bookingData.servicePerformeds.reduce((total, easp) => {
            return (total + easp.service.name + ' ')
          }, ''),
          bookingData,
        })
      });
      const paymentResponse = await response.json();
      console.log('paymentResponse:', paymentResponse)
      if (paymentResponse.newRecord) {
        // console.log(paymentResponse)
        setPaymentStatus('Payment successful');
        const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
        // squareButton.classList.add('green');
        // setTimeout(() => squareButton.classList.remove('green'), 3000);
        handleFieldChange('patientPayments', updatedServices);
        handleFieldChange('patientPaid', true);
        window.location.reload();
      } else {
        setPaymentStatus('Payment failed');
        setIsBraintreeDisabled(false)
        setIsDisabled(false)
        // squareButton.classList.add('red');
        // const style = document.createElement('style');
        // style.type = 'text/css';
        //         style.innerHTML = `
        //   .c-jWYnUm {
        //     background-color: red !important;
        //     color: white !important;
        //   }
        // `;
        //         document.head.appendChild(style);
      }
    } catch (error) {
      console.error('Error processing payment:', error);
      setPaymentStatus('Error processing payment');
      //       squareButton.style.backgroundColor = 'red';
      //       const style = document.createElement('style');
      //       style.type = 'text/css';
      //       style.innerHTML = `
      // .c-jWYnUm {
      //   background-color: red !important;
      //   color: white !important;
      // }
      // `;
      // document.head.appendChild(style);
    }
  }

  // handles payment submission
  const handleCCPayment = async (paymentResult) => {
    const squareButton = document.getElementById('rswp-card-button');

    if (paymentResult.token) {
      try {
        const response = await fetch('/api/processPayment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            nonce: paymentResult.token,
            totalAmount: dueAmount,
            bookingUuid: bookingData.uuid,
            tip: bookingData.tip,
            note: bookingData.servicePerformeds.reduce((total, easp) => {
              return (total + easp.service.name + ' ')
            }, ''),
            bookingData,
          })
        });
        const paymentResponse = await response.json();
        console.log('paymentResponse:', paymentResponse)
        if (paymentResponse.newRecord) {
          // console.log(paymentResponse)
          setPaymentStatus('Payment successful');
          const updatedServices = [...bookingData.patientPayments, paymentResponse.newRecord];
          squareButton.classList.add('green');
          setTimeout(() => squareButton.classList.remove('green'), 3000);
          handleFieldChange('patientPayments', updatedServices);
          handleFieldChange('patientPaid', true);
          window.location.reload();
        } else {
          setPaymentStatus('Payment failed');
          squareButton.classList.add('red');
          const style = document.createElement('style');
          style.type = 'text/css';
          style.innerHTML = `
    .c-jWYnUm {
      background-color: red !important;
      color: white !important;
    }
  `;
          document.head.appendChild(style);
        }
      } catch (error) {
        console.error('Error processing payment:', error);
        setPaymentStatus('Error processing payment');
        squareButton.style.backgroundColor = 'red';
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `
  .c-jWYnUm {
    background-color: red !important;
    color: white !important;
  }
`;
        document.head.appendChild(style);
      }
    }
  };

  // ensures entire input is highlighted when input is selected
  const handleFocus = (event) => event.target.select();

  // listener for input enter key
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleCustomTipBlur();
      e.target.blur();
    }
  };

  // listener for tip deselect as alternative to enter
  const handleCustomTipBlur = () => {
    const tipValueDollars = parseFloat(bookingData.tip); // Assuming inputTipValue is in dollars
    setTipWasSelected(true);
    handleFieldChange('tip', tipValueDollars)
  };

  // applies percent based tips, percent passed as integer
  const applyTip = (percentage) => {
    setTipWasSelected(true)
    const servicesTotal = bookingData.servicePerformeds.reduce((sum, item) => sum + parseFloat(item.service.price) * item.quantity, 0);
    const tipValue = servicesTotal * (percentage / 100);
    handleFieldChange('tip', Number(tipValue));
  };

  // handler for custom tip input box
  const handleCustomTipChange = (e) => {
    // add handling for validation
    handleFieldChange('tip', (e.target.value));
  };

  // general handler for booking data modifications; currently takes tip
  const handleFieldChange = (field, value) => {
    console.log('bookingData', bookingData)
    const keys = field.split('.')
    setBookingData(prevData => {
      const data = { ...prevData }
      keys.reduce((o, k, i) => {
        if (i === keys.length - 1) {
          o[k] = value
        } else {
          o[k] = o[k] || {}
        }
        return o[k]
      }, data)
      return data
    })
  }

  return (
    <>
        <Navbar/>
        <div className='payment-container'>
        <h1 style={{ textAlign: 'center' }}>Checkout</h1>
        <div className='services-table'>
    <table>
      <thead>
        <tr>
          <th>Service</th>
          <th>Amount</th>
          <th>Quantity</th>
        </tr>
      </thead>
      <tbody>
        {bookingData.servicePerformeds && bookingData.servicePerformeds.map((servicePerformed) => (
          <tr key={servicePerformed.uuid}>
            <td>{servicePerformed.service.name}</td>
            <td>${formatPrice(servicePerformed.service.price)}</td>
            <td>{servicePerformed.quantity}</td>
          </tr>
        ))}
        <tr>
            <td colSpan="2" className='total-label'>Tip</td>
            <td className='total-value'>
              <div>${formatPrice(bookingData.tip)}</div>
            </td>
          </tr>
        <tr>
            <td colSpan="2" className='total-label'>Discount</td>
            <td className='total-value'>
            $<input
              type="text"
              value={formatPrice(bookingData.discount)}
              disabled
              className='discount-input'
              style={{ maxWidth: '60px' }}
            />
            </td>
          </tr>
          <tr>
            <td colSpan="2" className='total-label'>Total</td>
            <td className='total-value'>${bookingData.servicePerformeds && formatPrice(totalAmount)}</td>
          </tr>
          <tr>
            <td colSpan="2" className='total-label'>Paid</td>
            <td className='total-value'>${formatPrice(paidAmount)}</td>
          </tr>
          <tr>
            <td colSpan="2" className='total-label'>Due</td>
            <td className='total-value'>${formatPrice(dueAmount)}</td>
          </tr>
      </tbody>
    </table>
    {dueAmount > 0.49 && !(bookingData.extraStuff && bookingData.extraStuff.serviceType === "weight loss") && <>
        <table>
        <thead>
          <tr>
            <th colSpan="6">Tip Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2"><button className="tip-button" onClick={() => applyTip(20)}>20%</button></td>
            <td colSpan="2"><button className="tip-button" onClick={() => applyTip(25)}>25%</button></td>
            <td colSpan="2"><button className="tip-button" onClick={() => applyTip(30)}>30%</button></td>
          </tr>
          <tr>
            <td colSpan="3">
              <button className="tip-button" onClick={() => { setTipWasSelected(true); handleFieldChange('tip', '0.00'); }}>No Tip</button>
            </td>
            <td colSpan="3">
              {customTip
                ? (
                  <>Tip Amount: $<input
        type="text"
        value={bookingData.tip}
        onChange={handleCustomTipChange}
        onBlur={handleCustomTipBlur}
        onKeyDown={handleKeyDown}
        className='tip-input'
        onFocus={handleFocus}
      /></>
                  )
                : (
                  <button className="tip-button" onClick={() => setCustomTip(true)}>Custom</button>
                  )}
            </td>
          </tr>
        </tbody>
      </table>
      <br/>
                  </>}
  </div>
        {dueAmount < 0.49 && bookingData.patientPayments && bookingData.patientPayments.length > 0 && (
          <div style={{
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: '20px',
            color: 'black',
            padding: '20px',
            margin: '20px',
            lineHeight: '1.2'
          }}>
      <div>
        Status: Paid
      </div>
      <div>Date: {formatDate(bookingData.patientPayments[0].createdAt)} </div>
      <div>
        Card: ****{bookingData.last4}
      </div>
      <br/>
      <div style={{ fontSize: '125%' }}>
        Thank you for choosing Luxe Mobile IV!
      </div>
    </div>

        )}
        {dueAmount > 0.49 && (tipWasSelected === true || (bookingData.extraStuff && bookingData.extraStuff.serviceType === "weight loss")) &&
        <div className=''>
        {(bookingData.extraStuff && bookingData.extraStuff.serviceType === "weight loss") && <br/>}
        {bookingData.cityCalendar.extraStuff.helcim && <><button
  style={{
    backgroundColor: isDisabled ? '#cccccc' : '#0F9E82', // Greyed out when disabled
    color: isDisabled ? '#666666' : 'white', // Darker text when disabled
    padding: '10px 20px', // Padding: 10px top and bottom, 20px left and right
    fontSize: '16px', // Font size
    border: 'none', // No border
    borderRadius: '5px', // Rounded corners
    cursor: 'pointer', // Cursor changes to a pointer on hover
    outline: 'none', // Remove outline on focus (for accessibility, consider keeping it or styling it appropriately)
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)' // Subtle shadow for depth
  }}
  className="tip-button"
  disabled={isDisabled} // Button is disabled based on isDisabled state
  onClick={handlePaymentClick}
>
  Pay With Card
</button><br/></>}

{bookingData.cityCalendar.extraStuff.braintree && <BraintreeDropIn show={true} onPaymentCompleted={processBraintreeTransaction} setIsDisabled={setIsDisabled} dueAmount={dueAmount}/>}<br/>
{paymentStatus && paymentStatus}

          {/* <PaymentForm
            applicationId={process.env.REACT_APP_SQUARE_APPLICATION_ID}
            locationId="LB0XPMNHK4RTY"
            createPaymentRequest={createPaymentRequest}
            cardTokenizeResponseReceived={handleCCPayment}
          >
            <fieldset className="sq-fieldset">
              <CreditCard />
              <ApplePay />
            </fieldset>
          </PaymentForm> */}
        </div>}
      </div>
      <Footer/>
      </>
  )
}

export default CheckoutSingle;
