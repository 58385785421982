import React, { useState, useEffect } from 'react';
import LogoutButton from './LogoutButton';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { jwtDecode } from 'jwt-decode';

const AdminHome = () => {
  const navigate = useNavigate(); // Use useNavigate hook
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState('');
  const [cityId, setCityId] = useState(1);

  useEffect(() => {
    // Check user's authentication status when component mounts
    fetch('/api/auth/status', {
      credentials: 'include' // necessary to include cookies
    })
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setUser(data.user)
          setCityId(data.user.cityId)
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      });

    const token = localStorage.getItem('jwtToken');
    if (token) {
      const decodedToken = jwtDecode(token);
      const acl = decodedToken.acl;
      const user = decodedToken.user;

      if (acl) {
        localStorage.setItem('acl', acl);
      }
      if (user) {
        localStorage.setItem('user', user);
      }
    }
  }, []);

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  return (
    <div className='adminParent'>
      <h1>Welcome to the Admin Dashboard</h1>
      <button onClick={() => navigate('/admin/byCity/' + cityId)}>City view</button><br />

      <button
      // onClick={() => navigate('/admin/payments')}
      >View Payments</button><br />
      {(user.acl > 2
        ? (<div>
        <button onClick={() => navigate('/admin/doctor-review')}>doctor review</button><br />
        </div>)
        : '')}
      <button onClick={() => navigate('/admin/inventoryHome')}>Inventory and service definitions</button><br />
      {user.acl > 4 && (
        <div>
          <h3> Weight Loss Orders </h3>
          <button onClick={() => navigate('/admin/bookingTableView/paidWLPickup/0')}>
            Paid orders ready for pick up
          </button>
          <br />
          <button onClick={() => navigate('/admin/bookingTableView/paidWLDelivery/0')}>
            Paid ready for delivery
          </button>
          <br/>
          <button onClick={() => navigate('/admin/bookingTableView/paidWLComplete/0')}>
            Completed Weight Loss orders
          </button>
          <br />
        </div>
      )}
      {user.acl > 5 && (
        <>
        <br/>
          <button onClick={() => navigate('/admin/treeViewer')}>
            Tree Session Viewer
          </button>
          <br />
          </>
      )}
      <br />
      <br />

      <LogoutButton />
    </div>
  );
}

export default AdminHome;
