import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { formatMedicalNotes } from './utility/module';

const aclLowest = 2;
const aclMedium = 3;
const aclHighest = 5;


const TableViewOfManyBooking = (props) => {
  const navigate = useNavigate();
  const { status, cityId } = useParams();

  // Check if status contains "WL" and set start date accordingly
  const initialStartDate = status.includes('WL')
    ? moment().subtract(30, 'days').startOf('day')
    : moment().startOf('day');

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]); // [bookings, setBookings
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(moment().endOf('day'));
  const [listOfStaffs, setListOfStaffs] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState('all');
  const [lastRefreshed, setLastRefreshed] = useState(moment().format('MMMM Do YYYY, h:mm:ss a')); // [lastRefreshed, setLastRefreshed
  const [user, setUser] = useState('');
  const [showMedicalHistory, setShowMedicalHistory] = useState(false);
  const [nurseIvStats, setNurseIvStats] = useState([]);

  useEffect(() => {
    // Check user's authentication status when component mounts
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setUser(data.user)
        } else {
          window.location.href = '/login';
        }
      });
  }, []);

  function formatDate (dateString) {
    return moment(dateString).local().format('dddd, MMMM D, YYYY, h:mm A');
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload();
    }, 300000); // 300000 milliseconds = 5 minutes
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const bodyJson = {
        status,
        cityId,
        // convert startDate and endDate to string with only the date part without moment
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      }
      // console.log('from TableViewOfManyBooking bodyJson', bodyJson)
      fetch('/api/admin/bookingTableView', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyJson)
      })
        .then(response => response.json())
        .then(data => {
          const sortedData = data.sort((a, b) => {
            const startTimeDifference = moment(b.startTime).diff(moment(a.startTime));
            if (startTimeDifference !== 0) {
              return startTimeDifference;
            }
            if (a.patient.lastName && b.patient.lastName) {
              return a.patient.lastName.localeCompare(b.patient.lastName);
            }
            return 0;
          });
          // sortedData = sortedData.map(booking => {
          //   booking.totalBaseIV = booking.servicePerformeds.reduce((acc, cv) => {
          //     if (cv.service.AddOn === false) {
          //       return (acc + 1)
          //     }
          //     return acc
          //   }, 0)
          //   // console.log('from TableViewOfManyBooking data', booking.totalBaseIV)
          //   return booking
          // })
          setBookings(sortedData)
          setFilteredBookings(sortedData)
          // console.log('from TableViewOfManyBooking data', sortedData)
          // perform a reduce function on the data to get a list of unique staff. Return staff.id, staff.firstName, and staff.lastName
          const staffList = data.reduce((acc, cv) => {
            if (!acc.find(e => e.id === cv.staff.id)) {
              acc.push({ id: cv.staff.id, firstName: cv.staff.firstName, lastName: cv.staff.lastName })
            }
            return acc
          }, [])
          setListOfStaffs(staffList)
        })
        .catch(error => console.error('Error fetching bookings:', error));
    }
  }, [isAuthenticated, status, cityId, startDate, endDate]);

  useEffect(() => {
    if (showMedicalHistory && user.acl >= aclHighest) { // Ensure ACL level and medical history visibility
      const statsMap = {};
  
      // Iterate through each booking to extract IV data and associate with nurses
      filteredBookings.forEach(booking => {
        const nurseId = booking.staff.id;
        const nurseName = `${booking.staff.firstName} ${booking.staff.lastName}`;
        const ivGauge = booking.patientMedicalNotes?.IVGauge;
        const ivAttempts = parseFloat(booking.patientMedicalNotes?.IVAttempts);
  
        if (!isNaN(ivAttempts) && ivGauge) {
          if (!statsMap[nurseId]) {
            statsMap[nurseId] = {
              nurseName,
              totalAttempts: 0,
              countAttempts: 0,
              countsPerGauge: {}
            };
          }
  
          // Accumulate total attempts and count
          statsMap[nurseId].totalAttempts += ivAttempts;
          statsMap[nurseId].countAttempts += 1;
  
          // Count attempts per gauge
          if (!statsMap[nurseId].countsPerGauge[ivGauge]) {
            statsMap[nurseId].countsPerGauge[ivGauge] = 0;
          }
          statsMap[nurseId].countsPerGauge[ivGauge] += 1;
        }
      });
  
      // Convert statsMap to an array for easier rendering
      const statsArray = Object.values(statsMap).map(nurseStat => {
        const meanAttempts = nurseStat.countAttempts > 0 ? (nurseStat.totalAttempts / nurseStat.countAttempts).toFixed(2) : '0.00';
        return {
          nurseName: nurseStat.nurseName,
          meanAttempts,
          countsPerGauge: nurseStat.countsPerGauge
        };
      });
  
      setNurseIvStats(statsArray);
    } else {
      setNurseIvStats([]);
    }
  }, [filteredBookings, showMedicalHistory, user.acl]);
  
  
  

  // Helper function to calculate mean
const calculateMean = (numbers) => {
  if (numbers.length === 0) return 0;
  const sum = numbers.reduce((acc, num) => acc + num, 0);
  return sum / numbers.length;
};

// Helper function to calculate median
const calculateMedian = (numbers) => {
  if (numbers.length === 0) return 0;
  const sorted = [...numbers].sort((a, b) => a - b);
  const mid = Math.floor(sorted.length / 2);
  if (sorted.length % 2 === 0) {
    // Even number of elements
    return (sorted[mid - 1] + sorted[mid]) / 2;
  } else {
    // Odd number of elements
    return sorted[mid];
  }
};


const IvAttemptsSummaryTable = () => {
  // Collect all unique gauges from the data
  const uniqueGauges = Array.from(new Set(nurseIvStats.flatMap(nurse => Object.keys(nurse.countsPerGauge))))
    .sort((a, b) => parseInt(a) - parseInt(b)); // Sort gauges numerically

  return (
    <div className="iv-attempts-summary-table">
      <h2>IV Attempts Summary by Nurse</h2>
      {nurseIvStats.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Nurse Name</th>
              <th>Mean IV Attempts</th>
              {uniqueGauges.map(gauge => (
                <th key={gauge}>{gauge}</th> // Append 'g' for gauge units
              ))}
            </tr>
          </thead>
          <tbody>
            {nurseIvStats.map((nurseStat, nurseIndex) => (
              <tr key={nurseIndex}>
                <td>{nurseStat.nurseName}</td>
                <td>{nurseStat.meanAttempts}</td>
                {uniqueGauges.map(gauge => (
                  <td key={gauge}>{nurseStat.countsPerGauge[gauge] || 0}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No IV Attempts data available.</p>
      )}
    </div>
  );
};

  if (!isAuthenticated) {
    return <div>Loading...</div>;
  }

  const getColor = (booking) => {
    if (moment(booking.updatedAt).add(1, 'hour') > moment()) return 'red';
    else return 'black'
  };

  // create function to handle the change event of the select staff drop down element
  const handleStaffChange = (e) => {
    setSelectedStaff(e.target.value)
    // console.log('from TableViewOfManyBooking data', bookings)
    // console.log('from TableViewOfManyBooking data', selectedStaff, e.target.value)
    let sortedData = bookings
    if (e.target.value !== 'all') {
      sortedData = sortedData.filter(booking => booking.primaryStaffId == e.target.value)
    }
    // console.log('from TableViewOfManyBooking sortedData', sortedData)
    // setBookings(sortedData)
    setFilteredBookings(sortedData)
  }



  const CommunicationLog = ({ communicationData }) => {
    return (
      <div>
        {communicationData && communicationData.map((entry, index) => (
          <div key={index}>
            Note: {entry.text} <br/>
            Author: {entry.author} <br/>
            Reason: {entry.reason} <br/>
            {index !== communicationData.length - 1 && <hr />}
          </div>
        ))}
      </div>
    );
  };

  const ReasonPieChart = ({ bookings }) => {
    // Get a count of each booking.customerServiceCommunicationJSON.reason
    const reasons = bookings.reduce((acc, cv) => {
      if (cv.customerServiceCommunicationJSON) {
        cv.customerServiceCommunicationJSON.forEach(reason => {
          if (!acc[reason.reason]) {
            acc[reason.reason] = 1
          } else {
            acc[reason.reason] += 1
          }
        })
      }
      return acc
    }, {})
    // Get a count of each booking.customerServiceCommunicationJSON.author
    const csStaffCount = bookings.reduce((acc, cv) => {
      if (cv.customerServiceCommunicationJSON) {
        cv.customerServiceCommunicationJSON.forEach(ea => {
          if (!acc[ea.author]) {
            acc[ea.author] = 1
          } else {
            acc[ea.author] += 1
          }
        })
      }
      return acc
    }, {})

    // Get a count of each booking.customerServiceCommunicationJSON.reason for each booking.customerServiceCommunicationJSON.author
    const csStaffReasonCount = bookings.reduce((acc, cv) => {
      if (cv.customerServiceCommunicationJSON) {
        cv.customerServiceCommunicationJSON.forEach(ea => {
          if (!acc[ea.author]) {
            acc[ea.author] = {}
          }
          if (!acc[ea.author][ea.reason]) {
            acc[ea.author][ea.reason] = 1
          } else {
            acc[ea.author][ea.reason] += 1
          }
        })
      }
      return acc
    }, {})

    // console.log('from TableViewOfManyBooking data', csStaffReasonCount)
    return (
      <div>
        <h2>Summary Of Reasons</h2>
        <ul>
          {Object.keys(reasons).map(reason => (
            <li key={reason}>{reason}: {reasons[reason]}</li>
          ))}
        </ul>
        <h2>Cancellation count by CS Staff</h2>
        <ul>
          {Object.keys(csStaffCount).map(csStaff => (
            <li key={csStaff}>{csStaff}: {csStaffCount[csStaff]}</li>
          ))}
        </ul>
        <h2>Summary Of Reasons By CS Staff In table form</h2>
        {/* create a table with rows for each author and columns for each reason */}
        <table>
          <thead>
            <tr>
              <th>Author</th>
              {Object.keys(reasons).map(reason => (
                <th key={reason}>{reason}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.keys(csStaffReasonCount).map(csStaff => (
              <tr key={csStaff}>
                <td>{csStaff}</td>
                {Object.keys(reasons).map(reason => (
                  <td key={reason}>{csStaffReasonCount[csStaff][reason] || 0}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <br/>
        <br/>
      </div>
    )
  }

  return (
    <div className='adminParent adminTable'>
      <h1>Bookings By Status For City Id {cityId}</h1>
      <div>
        <label htmlFor="startDate">Start Date:</label>
        <input
          type="date"
          id="startDate"
          value={startDate.format('YYYY-MM-DD')}
          onChange={e => setStartDate(moment(e.target.value).startOf('day'))}
        />
        <label htmlFor="endDate"> End Date:</label>
        <input
          type="date"
          id="endDate"
          value={endDate.format('YYYY-MM-DD')}
          onChange={e => setEndDate(moment(e.target.value).endOf('day'))}
        />
      {'   '}
      {(user.acl > aclLowest)
        ? (<span>
          <label htmlFor="staff">Staff:</label>
          <select name="staff" id="staff" onChange={handleStaffChange}>
          <option value="all">All</option>
          {listOfStaffs.map(staff => (
            <option key={staff.id} value={staff.id}>{staff.firstName} {staff.lastName}</option>
          ))}
          </select>
          </span>)
        : '' }
      <br />
      {/** create a check box for show medical history */}
      {user.acl > aclHighest && <div>
          <label htmlFor="showMedicalHistory">Show Medical History</label>
          <input
            type="checkbox"
            id="showMedicalHistory"
            checked={showMedicalHistory}
            onChange={e => setShowMedicalHistory(e.target.checked)} />
        </div>}
      <br />
      </div>
      <table>
        <thead>
          <tr>
            <th>Start Time</th>
            {user.acl > aclLowest ? <th>Staff Name</th> : null}
            <th>Payments</th>
            <th>Services Performed</th>
            <th>Patient Name</th>
            {
                (status === 'all' ? <th>Status</th> : null)
              }
            <th>View</th>
            {
                ((status === 'canceled' && user.acl > aclMedium) ? <th>Reason</th> : null)
              }
            {(user.acl > aclHighest && showMedicalHistory) && <th>Medical History</th>}
            
          </tr>
        </thead>
        <tbody>
          {filteredBookings.map(booking => (
            <tr key={booking.id} style={{ color: getColor(booking) }}>
              <td>{formatDate(booking.startTime)}</td>
              {user.acl > aclLowest ? <td>{`${booking.staff.firstName} ${booking.staff.lastName}`}</td> : null}
              {/* <td>{`${booking.staff.firstName} ${booking.staff.lastName}`}</td> */}
              <td>
                ${booking.patientPayments.reduce((acc, cv) => { return Number(acc) + Number(cv.amount) }, Number(0))} <br/>
                {(booking.patientPayments.length > 0) ? booking.patientPayments[0].method : null }
              </td>
              <td>{booking.servicePerformeds.map(service => (
                <span key={service.uuid} value={service.serviceUuid}>
                  {service.service && service.service.name}
                <br/></span>
              ))}</td>
              <td>{booking.patient.firstName} {booking.patient.lastName}</td>
              {
                (status === 'all' ? <td>{booking.status}</td> : null)
              }
              <td><button onClick={() => navigate(`/admin/bookingNew/${booking.uuid}`)}>view</button><br /></td>
              {
                ((status === 'canceled' && user.acl > aclMedium) ? <td> <CommunicationLog communicationData={booking.customerServiceCommunicationJSON} /> </td> : null)
              }
              {(user.acl > 5 && showMedicalHistory) ? <td>{formatMedicalNotes(booking.patientMedicalNotes)}</td> : null}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="calendar-bottom-space">
      {/* IV Attempts Summary Table at the Bottom */}
{(user.acl > aclHighest && showMedicalHistory) && <IvAttemptsSummaryTable />}

  <button onClick={() => {
    console.log('Status:', status);
    console.log('City ID:', cityId);
    if (status.includes('WL')) {
      navigate('/admin/');
    } else {
      navigate('/admin/byCity/' + cityId);
    }
  }} className="navigate-button">
    Back
  </button>
</div>
      <p> Last page refresh: {lastRefreshed} <br />
        <span style={{ color: 'red' }}> Bookings in red were updated within the last hour </span>
      </p>
      {(status === 'canceled' && user.acl > aclHighest) ? <ReasonPieChart bookings={filteredBookings} /> : null }
    </div>
  );
}

export default TableViewOfManyBooking;
