import React, { useState } from 'react';
import moment from 'moment';

const PatientLookup = () => {
  const [searchType, setSearchType] = useState('name');
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');

  const handleSearch = async (e) => {
    e.preventDefault();
    setError('');
    setSearchResults([]);

    if (!searchValue.trim()) {
      setError('Please enter a search value');
      return;
    }

    try {
      const response = await fetch('/api/admin/searchPatients', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          searchType,
          searchValue: searchValue.trim()
        })
      });

      if (!response.ok) {
        throw new Error('Failed to fetch patients');
      }

      const data = await response.json();
      setSearchResults(data);
    } catch (err) {
      setError('An error occurred while searching for patients');
      console.error('Error searching patients:', err);
    }
  };

  return (
    <div className="patient-lookup">
      <h2>Patient Lookup</h2>
      <form onSubmit={handleSearch}>
        <div>
          <label>
            Search by:
            <select 
              value={searchType} 
              onChange={(e) => setSearchType(e.target.value)}
            >
              <option value="name">Name</option>
              <option value="phone">Phone Number</option>
              <option value="email">Email</option>
            </select>
          </label>
        </div>
        <div>
          <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={`Enter patient ${searchType}`}
          />
        </div>
        <button type="submit">Search</button>
      </form>

      {error && <p className="error">{error}</p>}

      {searchResults.length > 0 && (
        <div className="search-results">
          <h3>Search Results:</h3>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>VIP Status</th>
                <th>Blacklisted</th>
                <th>Last Booking</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((patient) => (
                <tr key={patient.uuid}>
                  <td>{`${patient.firstName} ${patient.lastName}`}</td>
                  <td>{patient.phone}</td>
                  <td>{patient.email}</td>
                  <td>{patient.isVIP ? 'Yes' : 'No'}</td>
                  <td>{patient.blackList ? 'Yes' : 'No'}</td>
                  <td>{patient.lastBooking ? moment(patient.lastBooking).format('YYYY-MM-DD') : 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PatientLookup;