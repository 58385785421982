import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminMenu = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState('');

  useEffect(() => {
    // Check user's authentication status when component mounts
    // This could be a call to your server or checking local storage/session storage for a valid token
    fetch('/api/auth/status')
      .then(response => response.json())
      .then(data => {
        if (data.authenticated) {
          setIsAuthenticated(true);
          setUser(data.user)
        } else {
          // If not authenticated, redirect to login
          window.location.href = '/login';
        }
      });
  }, []);

  return (
    <div className='adminMenu adminParent' style={{ textAlign: 'left' }}>
      <h1>Welcome to the Admin Dashboard</h1>
      {(user.acl > 4
        ? (<div>
            <button onClick={() => navigate('/admin/supply')}>Define Drip Supplies</button><br />
        </div>)
        : '')}
      <button onClick={() => navigate('/admin/inventory')}>Inventory Management</button><br />
      <button onClick={() => navigate('/admin')}>Back</button><br />
    </div>
  );
}

export default AdminMenu;
